import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';

// Context
import ContextConsumer from '../../../common/contextProvider';

const Contacts = styled.article`
  margin-bottom: 30px;
  width: 100%;
  @media only screen and (max-width: ${props => props.theme.responsive.small}) {
    margin: 20px 0 10px;
  }
  p {
    color: ${props => props.theme.colors.text.normal};
    font: 14px/17px ${props => props.theme.fonts.body};
    margin-bottom: 0;
    display: block;
    &:first-child {
      margin-bottom: 5px;
    }
    a {
      color: ${props => props.theme.colors.text.normal};
      text-decoration: none;
      &:hover {
        color: ${props => props.theme.colors.primary};
      }
    }
  }
`;

const Contact = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          contentfulCompanyInformation {
            name
            phone
            email
          }
        }
      `}
      render={data => {
        const { name, phone, email } = data.contentfulCompanyInformation;
        const cleaned = ('' + phone).replace(/\D/g, '');

        const LinkPhoneNumber = '+61' + cleaned.replace(/\b0+/g, '');

        function LocalPhoneNumber() {
          const match = cleaned.match(/^(\d{2})(\d{4})(\d{4})$/);
          if (match) {
            return '(' + match[1] + ') ' + match[2] + ' ' + match[3];
          }
          return null;
        }

        function IntPhoneNumber() {
          const match = cleaned.match(/^(\d{1})(\d{1})(\d{4})(\d{4})$/);
          if (match) {
            return '+61 (' + match[2] + ') ' + match[3] + ' ' + match[4];
          }
          return null;
        }

        return (
          <ContextConsumer>
            {({ countryCode }) => (
              <Contacts>
                <p>
                  Phone number:&nbsp;
                  <a
                    href={`tel:${LinkPhoneNumber}`}
                    title={`Call ${name} on ${LinkPhoneNumber}`}
                  >
                    {countryCode !== 'AU' ? (
                      <IntPhoneNumber />
                    ) : (
                      <LocalPhoneNumber />
                    )}
                  </a>
                </p>
                <p>
                  Email address:&nbsp;
                  <a
                    href={`mailto: ${email}`}
                    title={`Email ${name} to ${email}`}
                  >
                    {email}
                  </a>
                </p>
              </Contacts>
            )}
          </ContextConsumer>
        );
      }}
    />
  );
};

export default Contact;
