import styled from 'styled-components';

const Inner = styled.div`
  text-align: center;
  margin: 0 auto;
  padding-bottom: 50px;
  overflow: auto;
  width: 80%;
  height: 100%;
  @media (min-width: ${props => props.theme.responsive.small}) {
    width: 70%;
  }
  @media (max-width: ${props => props.theme.responsive.xsmall}) {
    width: 90%;
  }
  &::-webkit-scrollbar {
    display: none;
  }
  h2 {
    margin-bottom: 2vw;
    text-transform: uppercase;
    text-align: center;
    @media (max-width: ${props => props.theme.responsive.xsmall}) {
      font-size: 1.2em;
    }
  }
`;

export default Inner;
