import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql, Link } from 'gatsby';

// Context
import ContextConsumer from '../../../../common/contextProvider';

const Wrapper = styled.span`
  a {
    padding: 0 20px;
    margin: 10px 0;
    color: ${props => props.theme.colors.text.light};
    padding-left: 0;
    &:last-child {
      padding-left: 20px;
    }
    &:hover {
      color: ${props => props.theme.colors.primary};
    }
  }
`;

const Contact = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          contentfulCompanyInformation(
            id: { eq: "59e3746d-e072-58a3-a341-04607ebd3e33" }
          ) {
            name
            phone
          }
        }
      `}
      render={data => {
        const { name, phone } = data.contentfulCompanyInformation;
        const cleaned = ('' + phone).replace(/\D/g, '');

        const LinkPhoneNumber = '+61' + cleaned.replace(/\b0+/g, '');

        function LocalPhoneNumber() {
          const match = cleaned.match(/^(\d{2})(\d{4})(\d{4})$/);
          if (match) {
            return '(' + match[1] + ') ' + match[2] + ' ' + match[3];
          }
          return null;
        }

        function IntPhoneNumber() {
          const match = cleaned.match(/^(\d{1})(\d{1})(\d{4})(\d{4})$/);
          if (match) {
            return '+61 (' + match[2] + ') ' + match[3] + ' ' + match[4];
          }
          return null;
        }

        return (
          <ContextConsumer>
            {({ countryCode }) => (
              <Wrapper>
                <a
                  href={`tel:${LinkPhoneNumber}`}
                  title={`Call ${name} on ${LinkPhoneNumber}`}
                >
                  {countryCode !== 'AU' ? (
                    <IntPhoneNumber />
                  ) : (
                    <LocalPhoneNumber />
                  )}
                </a>
                |
                <Link to="/contact" title={`Contact for ${name}`}>
                  290 Main North Road, Prospect. South Australia
                </Link>
              </Wrapper>
            )}
          </ContextConsumer>
        );
      }}
    />
  );
};

export default Contact;
