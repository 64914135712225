import React from 'react';

export const DropdownArrow = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 129 129">
    <path d="m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z" />
  </svg>
);

export const ArrowRight = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 129 129">
    <path d="m40.4,121.3c-0.8,0.8-1.8,1.2-2.9,1.2s-2.1-0.4-2.9-1.2c-1.6-1.6-1.6-4.2 0-5.8l51-51-51-51c-1.6-1.6-1.6-4.2 0-5.8 1.6-1.6 4.2-1.6 5.8,0l53.9,53.9c1.6,1.6 1.6,4.2 0,5.8l-53.9,53.9z" />
  </svg>
);

export const PhoneIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 473.806 473.806">
    <path d="M374.456,293.506c-9.7-10.1-21.4-15.5-33.8-15.5c-12.3,0-24.1,5.3-34.2,15.4l-31.6,31.5c-2.6-1.4-5.2-2.7-7.7-4    c-3.6-1.8-7-3.5-9.9-5.3c-29.6-18.8-56.5-43.3-82.3-75c-12.5-15.8-20.9-29.1-27-42.6c8.2-7.5,15.8-15.3,23.2-22.8    c2.8-2.8,5.6-5.7,8.4-8.5c21-21,21-48.2,0-69.2l-27.3-27.3c-3.1-3.1-6.3-6.3-9.3-9.5c-6-6.2-12.3-12.6-18.8-18.6    c-9.7-9.6-21.3-14.7-33.5-14.7s-24,5.1-34,14.7c-0.1,0.1-0.1,0.1-0.2,0.2l-34,34.3c-12.8,12.8-20.1,28.4-21.7,46.5    c-2.4,29.2,6.2,56.4,12.8,74.2c16.2,43.7,40.4,84.2,76.5,127.6c43.8,52.3,96.5,93.6,156.7,122.7c23,10.9,53.7,23.8,88,26    c2.1,0.1,4.3,0.2,6.3,0.2c23.1,0,42.5-8.3,57.7-24.8c0.1-0.2,0.3-0.3,0.4-0.5c5.2-6.3,11.2-12,17.5-18.1c4.3-4.1,8.7-8.4,13-12.9    c9.9-10.3,15.1-22.3,15.1-34.6c0-12.4-5.3-24.3-15.4-34.3L374.456,293.506z M410.256,398.806    C410.156,398.806,410.156,398.906,410.256,398.806c-3.9,4.2-7.9,8-12.2,12.2c-6.5,6.2-13.1,12.7-19.3,20    c-10.1,10.8-22,15.9-37.6,15.9c-1.5,0-3.1,0-4.6-0.1c-29.7-1.9-57.3-13.5-78-23.4c-56.6-27.4-106.3-66.3-147.6-115.6    c-34.1-41.1-56.9-79.1-72-119.9c-9.3-24.9-12.7-44.3-11.2-62.6c1-11.7,5.5-21.4,13.8-29.7l34.1-34.1c4.9-4.6,10.1-7.1,15.2-7.1    c6.3,0,11.4,3.8,14.6,7c0.1,0.1,0.2,0.2,0.3,0.3c6.1,5.7,11.9,11.6,18,17.9c3.1,3.2,6.3,6.4,9.5,9.7l27.3,27.3    c10.6,10.6,10.6,20.4,0,31c-2.9,2.9-5.7,5.8-8.6,8.6c-8.4,8.6-16.4,16.6-25.1,24.4c-0.2,0.2-0.4,0.3-0.5,0.5    c-8.6,8.6-7,17-5.2,22.7c0.1,0.3,0.2,0.6,0.3,0.9c7.1,17.2,17.1,33.4,32.3,52.7l0.1,0.1c27.6,34,56.7,60.5,88.8,80.8    c4.1,2.6,8.3,4.7,12.3,6.7c3.6,1.8,7,3.5,9.9,5.3c0.4,0.2,0.8,0.5,1.2,0.7c3.4,1.7,6.6,2.5,9.9,2.5c8.3,0,13.5-5.2,15.2-6.9    l34.2-34.2c3.4-3.4,8.8-7.5,15.1-7.5c6.2,0,11.3,3.9,14.4,7.3c0.1,0.1,0.1,0.1,0.2,0.2l55.1,55.1    C420.456,377.706,420.456,388.206,410.256,398.806z" />
    <path d="M256.056,112.706c26.2,4.4,50,16.8,69,35.8s31.3,42.8,35.8,69c1.1,6.6,6.8,11.2,13.3,11.2c0.8,0,1.5-0.1,2.3-0.2    c7.4-1.2,12.3-8.2,11.1-15.6c-5.4-31.7-20.4-60.6-43.3-83.5s-51.8-37.9-83.5-43.3c-7.4-1.2-14.3,3.7-15.6,11    S248.656,111.506,256.056,112.706z" />
    <path d="M473.256,209.006c-8.9-52.2-33.5-99.7-71.3-137.5s-85.3-62.4-137.5-71.3c-7.3-1.3-14.2,3.7-15.5,11    c-1.2,7.4,3.7,14.3,11.1,15.6c46.6,7.9,89.1,30,122.9,63.7c33.8,33.8,55.8,76.3,63.7,122.9c1.1,6.6,6.8,11.2,13.3,11.2    c0.8,0,1.5-0.1,2.3-0.2C469.556,223.306,474.556,216.306,473.256,209.006z" />
  </svg>
);

export const CloseIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 47.971 47.971">
    <path d="M28.228,23.986L47.092,5.122c1.172-1.171,1.172-3.071,0-4.242c-1.172-1.172-3.07-1.172-4.242,0L23.986,19.744L5.121,0.88c-1.172-1.172-3.07-1.172-4.242,0c-1.172,1.171-1.172,3.071,0,4.242l18.865,18.864L0.879,42.85c-1.172,1.171-1.172,3.071,0,4.242C1.465,47.677,2.233,47.97,3,47.97s1.535-0.293,2.121-0.879l18.865-18.864L42.85,47.091c0.586,0.586,1.354,0.879,2.121,0.879s1.535-0.293,2.121-0.879c1.172-1.171,1.172-3.071,0-4.242L28.228,23.986z" />
  </svg>
);

export const Facebook = (
  <svg width="35" height="35" viewBox="0 0 82 82">
    <path d="M32.3 34.9H36v-3.6c0-1.6 0-4.1 1.2-5.6 1.2-1.6 2.9-2.7 5.8-2.7 4.7 0 6.7.7 6.7.7l-.9 5.5s-1.6-.4-3-.4-2.7.5-2.7 2V35H49l-.4 5.4H43V59h-7V40.3h-3.7v-5.4z" />
  </svg>
);

export const Twitter = (
  <svg width="35" height="35" viewBox="0 0 82 82">
    <path d="M59 31.8c-1.3.6-2.7 1-4.2 1.2 1.5-.9 2.7-2.4 3.2-4.1-1.4.8-3 1.5-4.7 1.8-1.3-1.4-3.3-2.3-5.4-2.3-4.1 0-7.4 3.3-7.4 7.4 0 .6.1 1.1.2 1.7-6.1-.3-11.6-3.2-15.2-7.7-.6 1.1-1 2.4-1 3.7 0 2.6 1.3 4.8 3.3 6.1-1.2 0-2.3-.4-3.3-.9v.1c0 3.6 2.5 6.6 5.9 7.2-.6.2-1.3.3-1.9.3-.5 0-.9 0-1.4-.1.9 2.9 3.7 5.1 6.9 5.1-2.5 2-5.7 3.2-9.2 3.2-.6 0-1.2 0-1.8-.1 3.3 2.1 7.2 3.3 11.3 3.3 13.6 0 21-11.3 21-21v-1c1.5-1.1 2.7-2.4 3.7-3.9" />
  </svg>
);

export const Instagram = (
  <svg width="35" height="35" viewBox="0 0 82 82">
    <path d="M41 82C18.4 82 0 63.6 0 41S18.4 0 41 0s41 18.4 41 41-18.4 41-41 41zm0-80C19.5 2 2 19.5 2 41s17.5 39 39 39 39-17.5 39-39S62.5 2 41 2z" />
    <path d="M41 25c5.2 0 5.8 0 7.9.1 1.9.1 2.9.4 3.6.7.9.4 1.6.8 2.2 1.5.7.7 1.1 1.3 1.5 2.2.3.7.6 1.7.7 3.6.1 2.1.1 2.7.1 7.9s0 5.8-.1 7.9c-.1 1.9-.4 2.9-.7 3.6-.4.9-.8 1.6-1.5 2.2-.7.7-1.3 1.1-2.2 1.5-.7.3-1.7.6-3.6.7-2.1.1-2.7.1-7.9.1s-5.8 0-7.9-.1c-1.9-.1-2.9-.4-3.6-.7-.9-.4-1.6-.8-2.2-1.5-.7-.7-1.1-1.3-1.5-2.2-.3-.7-.6-1.7-.7-3.6-.1-2.1-.1-2.7-.1-7.9s0-5.8.1-7.9c.1-1.9.4-2.9.7-3.6.4-.9.8-1.6 1.5-2.2.7-.7 1.3-1.1 2.2-1.5.7-.3 1.7-.6 3.6-.7 2.1-.1 2.7-.1 7.9-.1m0-3.5c-5.3 0-6 0-8 .1-2.1.1-3.5.4-4.7.9-1.3.5-2.4 1.2-3.5 2.2-1.1 1.1-1.8 2.2-2.2 3.5-.5 1.2-.8 2.7-.9 4.7-.1 2.1-.1 2.7-.1 8s0 6 .1 8c.1 2.1.4 3.5.9 4.7.5 1.3 1.2 2.4 2.2 3.5 1.1 1.1 2.2 1.8 3.5 2.2 1.2.5 2.7.8 4.7.9 2.1.1 2.7.1 8 .1s6 0 8-.1c2.1-.1 3.5-.4 4.7-.9 1.3-.5 2.4-1.2 3.5-2.2 1.1-1.1 1.8-2.2 2.2-3.5.5-1.2.8-2.7.9-4.7.1-2.1.1-2.7.1-8s0-6-.1-8c-.1-2.1-.4-3.5-.9-4.7-.5-1.3-1.2-2.4-2.2-3.5-1.1-1.1-2.2-1.8-3.5-2.2-1.2-.5-2.7-.8-4.7-.9-2-.1-2.7-.1-8-.1z" />
    <path d="M41 31c-5.5 0-10 4.5-10 10s4.5 10 10 10 10-4.5 10-10-4.5-10-10-10zm0 16.5c-3.6 0-6.5-2.9-6.5-6.5s2.9-6.5 6.5-6.5 6.5 2.9 6.5 6.5-2.9 6.5-6.5 6.5z" />
    <circle cx="51.4" cy="30.6" r="2.3" />
  </svg>
);

export const TripAdvisor = (
  <svg width="35" height="35" viewBox="0 0 82 82">
    <circle cx="28.6" cy="43.1" r="1.9" />
    <circle cx="52.6" cy="43.1" r="1.9" />
    <path d="M28.9 37.3c-3.2 0-5.8 2.6-5.8 5.8 0 3.2 2.6 5.8 5.8 5.8 3.2 0 5.8-2.6 5.8-5.8 0-3.2-2.6-5.8-5.8-5.8zm0 9.8c-2.2 0-4-1.8-4-4s1.8-4 4-4 4 1.8 4 4-1.8 4-4 4zM65 31.4h-8c-4.1-2.7-9.6-4.4-16-4.4s-12.3 1.7-16.4 4.4H17c1.2 1.4 2.2 3.4 2.4 4.8-1.4 2-2.3 4.4-2.3 7 0 6.6 5.3 11.9 11.9 11.9 3.7 0 7.1-1.7 9.3-4.4.9 1 2.4 3.1 2.7 3.8 0 0 1.7-2.6 2.7-3.7 2.2 2.7 5.5 4.4 9.3 4.4 6.6 0 11.9-5.3 11.9-11.9 0-2.6-.9-5-2.3-7 .2-1.5 1.1-3.5 2.4-4.9zM29.1 52.8c-5.3 0-9.6-4.3-9.6-9.6s4.3-9.6 9.6-9.6 9.6 4.3 9.6 9.6c0 5.2-4.3 9.6-9.6 9.6zm0-21.6c3.4-1.5 7.7-2.3 11.9-2.3 4.3 0 8.1.8 11.6 2.3-6.5.2-11.6 5.5-11.6 11.9 0-6.5-5.3-11.9-11.9-11.9zm23.8 21.6c-5.3 0-9.6-4.3-9.6-9.6s4.3-9.6 9.6-9.6 9.6 4.3 9.6 9.6c0 5.2-4.3 9.6-9.6 9.6zm0-15.5c-3.2 0-5.8 2.6-5.8 5.8 0 3.2 2.6 5.8 5.8 5.8 3.2 0 5.8-2.6 5.8-5.8 0-3.2-2.6-5.8-5.8-5.8zm0 9.8c-2.2 0-4-1.8-4-4s1.8-4 4-4 4 1.8 4 4c.1 2.2-1.7 4-4 4zm14.8-13.8h-.4v-1.6h.6c.3 0 .6 0 .6.5 0 .2-.1.4-.3.4l.4.7h-.4l-.3-.6h-.1v.6zm0-.9h.3c.1 0 .1-.1.1-.2s0-.2-.1-.2h-.3v.4z" />
    <path d="M67.9 31.1c-.8 0-1.4.6-1.4 1.4 0 .8.6 1.4 1.4 1.4.8 0 1.4-.6 1.4-1.4.1-.7-.6-1.4-1.4-1.4zm0 2.6c-.6 0-1.2-.5-1.2-1.2 0-.6.5-1.2 1.2-1.2.6 0 1.2.5 1.2 1.2s-.5 1.2-1.2 1.2z" />
  </svg>
);

export const ModalClose = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 47.971 47.971">
    <path d="M28.228,23.986L47.092,5.122c1.172-1.171,1.172-3.071,0-4.242c-1.172-1.172-3.07-1.172-4.242,0L23.986,19.744L5.121,0.88c-1.172-1.172-3.07-1.172-4.242,0c-1.172,1.171-1.172,3.071,0,4.242l18.865,18.864L0.879,42.85c-1.172,1.171-1.172,3.071,0,4.242C1.465,47.677,2.233,47.97,3,47.97s1.535-0.293,2.121-0.879l18.865-18.864L42.85,47.091c0.586,0.586,1.354,0.879,2.121,0.879s1.535-0.293,2.121-0.879c1.172-1.171,1.172-3.071,0-4.242L28.228,23.986z" />
  </svg>
);

export const Calendar = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path d="M467,60h-15V45c0-24.813-20.187-45-45-45s-45,20.187-45,45v15h-60V45c0-24.813-20.187-45-45-45s-45,20.187-45,45v15h-62V45c0-24.813-20.187-45-45-45S60,20.187,60,45v15H45C20.187,60,0,80.187,0,105c0,2.176,0,356.248,0,362c0,24.813,20.187,45,45,45h422c24.813,0,45-20.187,45-45c0-2.763,0-357.126,0-362C512,80.187,491.813,60,467,60z M392,45c0-8.271,6.729-15,15-15s15,6.729,15,15v60c0,8.271-6.729,15-15,15s-15-6.729-15-15V45z M242,45c0-8.271,6.729-15,15-15s15,6.729,15,15v60c0,8.271-6.729,15-15,15s-15-6.729-15-15V45z M90,45c0-8.271,6.729-15,15-15s15,6.729,15,15v60c0,8.271-6.729,15-15,15s-15-6.729-15-15V45z M482,467c0,8.271-6.729,15-15,15H45c-8.271,0-15-6.729-15-15V212h452V467z M482,182H30v-77c0-8.271,6.729-15,15-15h15v15c0,24.813,20.187,45,45,45s45-20.187,45-45V90h62v15c0,24.813,20.187,45,45,45s45-20.187,45-45V90h60v15c0,24.813,20.187,45,45,45s45-20.187,45-45V90h15c8.271,0,15,6.729,15,15V182z" />
    <path d="M437,242H75c-8.284,0-15,6.716-15,15v180c0,8.284,6.716,15,15,15h362c8.284,0,15-6.716,15-15V257C452,248.716,445.284,242,437,242z M150,422H90v-60h60V422z M150,332H90v-60h60V332z M242,422h-62v-60h62V422z M242,332h-62v-60h62V332z M332,422h-60v-60h60V422z M332,332h-60v-60h60V332z M422,422h-60v-60h60V422z M422,332h-60v-60h60V332z" />
  </svg>
);

export const Clock = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 97.16 97.16">
    <path d="M48.58,0C21.793,0,0,21.793,0,48.58s21.793,48.58,48.58,48.58s48.58-21.793,48.58-48.58S75.367,0,48.58,0z M48.58,86.823c-21.087,0-38.244-17.155-38.244-38.243S27.493,10.337,48.58,10.337S86.824,27.492,86.824,48.58S69.667,86.823,48.58,86.823z" />
    <path d="M73.898,47.08H52.066V20.83c0-2.209-1.791-4-4-4c-2.209,0-4,1.791-4,4v30.25c0,2.209,1.791,4,4,4h25.832c2.209,0,4-1.791,4-4S76.107,47.08,73.898,47.08z" />
  </svg>
);

export const MapPointer = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
    <path d="M42.117,43.007c-0.55-0.067-1.046,0.327-1.11,0.876s0.328,1.046,0.876,1.11C52.399,46.231,58,49.567,58,51.5c0,2.714-10.652,6.5-28,6.5S2,54.214,2,51.5c0-1.933,5.601-5.269,16.117-6.507c0.548-0.064,0.94-0.562,0.876-1.11c-0.065-0.549-0.561-0.945-1.11-0.876C7.354,44.247,0,47.739,0,51.5C0,55.724,10.305,60,30,60s30-4.276,30-8.5C60,47.739,52.646,44.247,42.117,43.007z" />
    <path d="M29.823,54.757L45.164,32.6c5.754-7.671,4.922-20.28-1.781-26.982C39.761,1.995,34.945,0,29.823,0s-9.938,1.995-13.56,5.617c-6.703,6.702-7.535,19.311-1.804,26.952L29.823,54.757z M30,12c3.86,0,7,3.141,7,7s-3.14,7-7,7s-7-3.141-7-7S26.14,12,30,12z" />
  </svg>
);
