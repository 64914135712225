import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

// Images
import AddIcon from '../../../../assets/images/icons/plus.svg';
import MinusIcon from '../../../../assets/images/icons/minus.svg';

// Context
import ContextConsumer from '../../../common/contextProvider';

const Wrapper = styled.span`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  @media (max-width: ${props => props.theme.responsive.medium}) {
    display: inline-block;
    margin-top: 20px;
  }
`;

const Widget = styled.div`
  position: relative;
  width: 100%;
  margin-right: 10px;
  @media (max-width: ${props => props.theme.responsive.medium}) {
    width: calc(50% - 5px);
    display: inline-block;
    &:nth-child(2) {
      margin-right: 0;
    }
  }
  &:last-child {
    margin-right: 0;
  }
  .label {
    text-transform: uppercase;
    font-size: 0.875em;
    padding: 1em 0 0;
    display: block;
  }
  .input-wrap {
    cursor: pointer;
    position: relative;
    border: 1px solid ${props => props.theme.colors.misc.border};
    background: ${props => props.theme.colors.background.light};
    padding: 1em;
    width: 100%;
    span {
      display: block;
      &.day {
        font-size: 0.75em;
      }
      &.date {
        margin-top: 5px;
        color: ${props => props.theme.colors.text.dark};
        font-family: ${props => props.theme.fonts.header};
        font-size: 2.6875em;
        line-height: 1;
      }
      &.month {
        font-size: 0.75em;
      }
    }
    .sideways {
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        &.quantity {
          position: relative;
          margin-top: 5px;
          color: ${props => props.theme.colors.text.dark};
          font-family: ${props => props.theme.fonts.header};
          font-size: 2.6875em;
          line-height: 1;
          select {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            opacity: 0;
          }
        }
        &.add {
          display: block;
          background-image: url(${AddIcon});
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          width: 25px;
          height: 25px;
          overflow: hidden;
          white-space: nowrap;
          transition: all 300ms ease-in-out;
          &.disabled {
            cursor: no-drop;
            filter: grayscale(1);
          }
        }
        &.minus {
          display: block;
          background-image: url(${MinusIcon});
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          width: 25px;
          height: 25px;
          overflow: hidden;
          white-space: nowrap;
          transition: all 300ms ease-in-out;
          &.disabled {
            cursor: no-drop;
            filter: grayscale(1);
          }
        }
      }
    }
  }
`;

const AdultsSelect = props => {
  const adults = [];
  for (let i = 1; i < props.numTimes; i += 1) {
    adults.push(props.children(i));
  }
  return adults;
};

const ChildsSelect = props => {
  const childs = [];
  for (let i = 0; i < props.numTimes; i += 1) {
    childs.push(props.children(i));
  }
  return childs;
};

const BookingInputs = () => (
  <ContextConsumer>
    {({
      calendarDates,
      adults,
      childs,
      toggleDates,
      handleChangeAdults,
      handleAddAdults,
      handleMinusAdults,
      handleChangeChilds,
      handleAddChilds,
      handleMinusChilds
    }) => {
      return (
        <Wrapper>
          <Widget>
            <div className="label">Arrival</div>
            <div role="button" className="input-wrap" onClick={toggleDates}>
              <span className="day">
                {moment(calendarDates.startDate).format('dddd')}
              </span>
              <span className="date">
                {moment(calendarDates.startDate).format('DD')}
              </span>
              <span className="month">
                {moment(calendarDates.startDate).format('MMM YYYY')}
              </span>
            </div>
          </Widget>
          <Widget>
            <div className="label">Departure</div>
            <div role="button" className="input-wrap" onClick={toggleDates}>
              <span className="day">
                {moment(calendarDates.endDate).format('dddd')}
              </span>
              <span className="date">
                {moment(calendarDates.endDate).format('DD')}
              </span>
              <span className="month">
                {moment(calendarDates.endDate).format('MMM YYYY')}
              </span>
            </div>
          </Widget>
          <Widget>
            <div className="label">Adults</div>
            <div className="input-wrap">
              <span className="day">&nbsp;</span>
              <div className="sideways">
                <span
                  className={`minus ${adults <= 1 ? 'disabled' : ''}`}
                  role="button"
                  name="adults"
                  onClick={adults <= 1 ? null : handleMinusAdults}
                />
                <span className="quantity">
                  <select value={adults} onChange={handleChangeAdults}>
                    <AdultsSelect numTimes={6}>
                      {index => (
                        <option key={index} value={index}>
                          {index} Adults
                        </option>
                      )}
                    </AdultsSelect>
                  </select>
                  {adults === 10 ? '' : '0'}
                  {adults}
                </span>
                <span
                  className={`add ${adults >= 5 ? 'disabled' : ''}`}
                  role="button"
                  name="adults"
                  onClick={adults >= 5 ? null : handleAddAdults}
                />
              </div>
              <span className="month">&nbsp;</span>
            </div>
          </Widget>
          <Widget>
            <div className="label">Children</div>
            <div className="input-wrap">
              <span className="day">&nbsp;</span>
              <div className="sideways">
                <span
                  className={`minus ${childs <= 0 ? 'disabled' : ''}`}
                  role="button"
                  name="childs"
                  onClick={childs <= 0 ? null : handleMinusChilds}
                />
                <span className="quantity">
                  <select value={childs} onChange={handleChangeChilds}>
                    <ChildsSelect numTimes={5}>
                      {index => (
                        <option key={index} value={index}>
                          {index} Children
                        </option>
                      )}
                    </ChildsSelect>
                  </select>
                  0{childs}
                </span>
                <span
                  className={`add ${childs >= 4 ? 'disabled' : ''}`}
                  role="button"
                  name="childs"
                  onClick={childs >= 4 ? null : handleAddChilds}
                />
              </div>
              <span className="month">&nbsp;</span>
            </div>
          </Widget>
        </Wrapper>
      );
    }}
  </ContextConsumer>
);

export default BookingInputs;
