import { createGlobalStyle } from 'styled-components';
import styledNormalize from 'styled-normalize';

// Animation
import { Pulse, FadeIn } from './animations';

// Fonts
import WeatherIcons from './weatherIcons.css';

// Styles
import SwiperCSS from './swiper.css';

export default createGlobalStyle`
  ${styledNormalize}
  ${SwiperCSS}
  ${WeatherIcons}

  html {
    box-sizing: border-box;
    animation-name: ${FadeIn};
    animation-duration: 1s;
    animation-delay: 0.3s;
    animation-iteration-count: 1;
    animation-timing-function: ease;
    animation-fill-mode: both;
  }

  html {
    position: relative;
    height: 100%;
    background: ${(props) => props.theme.colors.background.light};
    color: ${(props) => props.theme.colors.text.normal};
  }

  html,
  body {
    height: 100%;
  }

  body {
    position: relative;
    min-height: 100%;
    &.active {
      overflow: hidden;
    }
  }

  html,
  body,
  input,
  textarea,
  button {
    font-family: ${(props) => props.theme.fonts.body};
    font-size: 18px;
    line-height: 1.6;
    outline: 0;
  }

  html,
  body,
  div,
  span,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  small, 
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  footer,
  header,
  section,
  video {
    margin: 0;
    padding: 0;
    border: 0;
    outline: none;
    font-size: 100%;
    vertical-align: baseline;
  }

  footer,
  header,
  section {
    display: block;
  }

  *,
  ::after,
  ::before {
    box-sizing: border-box;
  }

  .hidden {
    display: none !important;
  }

  ::selection {
    background: ${(props) => props.theme.colors.misc.selection};
    color: ${(props) => props.theme.colors.text.light};
  }

  /* ::-webkit-scrollbar {
    @media only screen and (min-width: ${(props) =>
      props.theme.responsive.medium}) {
      width: 6px;
    }
  }

  ::-webkit-scrollbar-thumb {
    @media only screen and (min-width: ${(props) =>
      props.theme.responsive.medium}) {
      background: ${(props) => props.theme.colors.primary};
    }
  }

  ::-webkit-scrollbar-track {
    @media only screen and (min-width: ${(props) =>
      props.theme.responsive.medium}) {
      background: ${(props) => props.theme.colors.background.dark};
    }
  } */

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 28px;
    @media only screen and (max-width: ${(props) =>
      props.theme.responsive.small}) {
      margin-bottom: 16px;
    }
  }
  

  h1 {
    font-size: 72px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.44;
    letter-spacing: normal;
    text-align: left;
    color: ${(props) => props.theme.colors.text.dark};
    @media only screen and (max-width: ${(props) =>
      props.theme.responsive.small}) {
      font-size: 40px;
      font-weight: 900;
      font-style: normal;
      font-stretch: normal;
      line-height: 0.8;
      letter-spacing: normal;
    }
    @media only screen and (max-width: ${(props) =>
      props.theme.responsive.xxsmall}) {
      font-size: 32px;
    }
  }

  h2 {
    font-size: 32px;
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.19;
    letter-spacing: normal;
    text-align: left;
    color: ${(props) => props.theme.colors.text.dark};
    @media only screen and (max-width: ${(props) =>
      props.theme.responsive.small}) {
      font-size: 26px;
      font-weight: 700;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.46;
      letter-spacing: normal;
    }
  }
  h3 {
    font-size: 24px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: left;
    color: ${(props) => props.theme.colors.text.dark};
    @media only screen and (max-width: ${(props) =>
      props.theme.responsive.small}) {
      font-size: 20px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.4;
      letter-spacing: normal;
    }
  }

  p {
    margin-bottom: 24px;
    @media only screen and (max-width: ${(props) =>
      props.theme.responsive.small}) {
      margin-bottom: 24px;
    }
  }

  a {
    color: ${(props) => props.theme.colors.primary};
    cursor: pointer;
    text-decoration: none;
    transition: all 300ms ease-in-out;
    &:hover {
      text-decoration: none;
      color: ${(props) => props.theme.colors.secondary};
    }
  }

  input {
    line-height: normal;
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    font-style: inherit;
    font-variant: inherit;
    font-weight: inherit;
    margin: 0;
    outline: 0;
    &[type='submit'] {
      cursor: pointer;
      border-color: ${(props) => props.theme.colors.misc.border};
      text-transform: uppercase;
      letter-spacing: 2px;
      display: block;
      transition: color 300ms, background-color 300ms, border-color 300ms;
      &:focus {
        outline: none;
      }
    }
    &[type="email"],
    &[type="number"],
    &[type="search"],
    &[type="tel"],
    &[type="text"] {
      appearance: none;
      border-radius: 0;
      padding: 0.5555555556rem 1rem;
      height: 2.7777777778rem;
      background-color: ${(props) => props.theme.colors.misc.input};
      border: 1px solid ${(props) => props.theme.colors.misc.input};
      box-sizing: border-box;
      color: ${(props) => props.theme.colors.text.dark};
      display: block;
      font-weight: 400;
      font-size: 1em;
      resize: none;
      border-radius: 6px;
      width: 100%;
      &:focus,
      &:hover {
        border: 1px solid ${(props) => props.theme.colors.secondary};
        transition: all 0.6s linear;
      }
    }
  }

  textarea {
    height: 7.2222222222rem;
    overflow: auto;
    outline: 0;
    appearance: none;
    border-radius: 0;
    padding: 0.5555555556rem 1rem;
    background-color: ${(props) => props.theme.colors.misc.input};
    border: 1px solid ${(props) => props.theme.colors.misc.input};
    box-sizing: border-box;
    color: ${(props) => props.theme.colors.text.dark};
    display: block;
    font-weight: 400;
    font-size: 1em;
    resize: none;
    border-radius: 6px;
    width: 100%;
    &:focus,
    &:hover {
      border: 1px solid ${(props) => props.theme.colors.secondary};
      transition: all 0.6s linear;
    }
  }

  input, select {
    display: block;
    font-size: 1rem;
    width: 100%;
  }

  button,
  input[type="submit"].button,
  a.button {
    cursor: pointer;
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    height: 50px;
    margin: 0;
    padding: 0 20px;
    background: ${(props) => props.theme.colors.primary};
    font: 700 16px/50px ${(props) => props.theme.fonts.body};
    text-transform: uppercase;
    text-align-last: auto;
    text-decoration: none;
    text-align: center;
    white-space: nowrap;
    color: ${(props) => props.theme.colors.text.light};
    transition: color 0.2s, background-color 0.2s, border-color 0.2s;
    overflow: hidden;
    text-overflow: ellipsis;
    outline: none;
    &:hover {
      background: ${(props) => props.theme.colors.secondary};
    }
  }

  ol, ul {
    list-style: none;
  }

  .pulse_holder {
    border-radius: 100%;
    width: 20px;
    height: 20px;
    .pulse_marker {
      display: block;
      width: 10px;
      height: 10px;
      background-color: ${(props) => props.theme.colors.primary};
      border-radius: 50%;
      background-position: 50% 50%;
      background-size: cover;
      &:before, &:after {
        content: "";
        position: absolute;
        background-color: rgb(254,224,92);
        border: 3px dotted rgb(255, 209, 5);
        border-radius: 100%;
        animation: ${Pulse} 2s ease-out infinite;
        border-image: initial;
      }
      &:before {
        left: -27px;
        top: -27px;
        width: 64px;
        height: 64px;
      }
      &:after {
        left: -59px;
        top: -59px;
        width: 128px;
        height: 128px;
      }
    }
  }

  .room-modal {
    .ReactModal__Overlay {
      /* transform: translateX(100%);
      transition: all 600ms ease-in-out; */
      &.ReactModal__Overlay--after-open{
        /* transform: translateX(0%); */
      }
      &.ReactModal__Overlay--before-close{
        /* transform: translateX(100%); */
      }
      .ReactModal__Content {
        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }

  /* Consent Manager Overlay */
  .e160cov61 {
    .e160cov63 {
      h2 {
        margin-bottom: 0;
        font-family: system-ui,-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto, Helvetica,Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji', 'Segoe UI Symbol';
        font-weight: 400;
      }
    }
    .e160cov62 {
      .css-7eturq-Content {
        font-size: 13px;
        line-height: 1.5
      }
      table {
        table-layout: inherit;
        th, td {
          &:first-child {
            padding-left: 12px;
          }
          &:last-child {
            display: none;
          }
        }
      }
      button {
        border-radius: 0;
        &[type="submit"] {
          padding: 0 16px;
          border: none;
          color: inherit;
          line-height: 1;
          cursor: pointer;
          transition: box-shadow 80ms ease-in-out;
          background-color: ${(props) => props.theme.colors.primary};
          background-image: none;
          color: ${(props) => props.theme.colors.text.dark};
          vertical-align: baseline;
        }
      }
    }
  }
`;
