import styled from 'styled-components';

const Wrapper = styled.footer`
  padding: 30px 0 50px 0;
  position: relative;
  background: ${props => props.theme.colors.background.dark};
  text-align: center;
  @media only screen and (max-width: ${props => props.theme.responsive.small}) {
    padding: 80px 0 120px 0;
  }
`;

export default Wrapper;
