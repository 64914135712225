module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../plugins/plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-modal-routing/gatsby-browser.js'),
      options: {"plugins":[],"modalProps":{"style":{"overlay":{"zIndex":1001,"top":0,"left":0,"right":0,"bottom":0,"backgroundColor":"transparent"},"content":{"position":"absolute","border":"none","boxShadow":"0 0.125rem 0.125rem 0 rgba(0,0,0,0.1)","background":"#ffffff","padding":0,"top":0,"left":"auto","bottom":0,"right":0,"borderRadius":0,"overflow":"auto","WebkitOverflowScrolling":"touch","maxWidth":"1000px","width":"100%","margin":"0"}},"portalClassName":"room-modal","contentLabel":"Room and Apartment Modal","closeTimout":600}},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#FEE05C","showSpinner":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.sombrero.com.au"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Comfort Inn & Suites Sombrero","short_name":"Comfort Inn & Suites Sombrero","start_url":"https://www.sombrero.com.au","background_color":"#ffffff","theme_color":"#fbb500","display":"standalone","icon":"./src/assets/images/logo/icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"e4009972f2e59afb9f60e693a791f70b"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
