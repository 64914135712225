import styled from 'styled-components';
import { Link } from 'gatsby';

const Wrapper = styled(Link)`
  padding: 12px 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #e26812;
  text-align: center;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    font-size: 12px;
    color: #ffffff;
    text-transform: uppercase;
  }
  div {
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: center;
    color: #e26812;
    background: #ffffff;
    padding: 2px 10px;
    border-radius: 4px;
    text-align: center;
    margin-left: 20px;
  }
`;

export default Wrapper;
