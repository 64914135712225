import React, { Component } from 'react';
import styled, { css } from 'styled-components';

// Animation
import { Dots } from '../../../../../assets/styles/animations';

function loadingChilds() {
  let loadingStyles = '';

  for (let i = 0; i < 9; i += 1) {
    loadingStyles += `
       &:nth-child(${i}) {
         animation-delay: ${i * 0.1}s;
       }
     `;
  }

  return css`
    ${loadingStyles}
  `;
}

const Wrapper = styled.div`
  cursor: pointer;
  width: 90px;
  color: ${(props) => props.theme.colors.text.light};
  > span {
    i {
      margin: 0 10px 0 20px;
    }
  }
`;

const Loading = styled.span`
  margin-left: 20px;
  font-size: 30px;
  line-height: 0.4;
  > span {
    animation: ${Dots} 1s infinite;
    animation-fill-mode: both;
    ${loadingChilds()}
  }
`;

const LoadingDots = (props) => {
  const items = [];
  for (let i = 0; i < props.numTimes; i += 1) {
    items.push(props.children(i));
  }
  return items;
};

class Weather extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      fahrenheit: null,
      celcius: null,
      iconCode: null,
      showCelcius: true
    };
  }

  weatherInit = () => {
    const CITY_LAT = '-34.8731221';
    const CITY_LON = '138.60144450000007';
    const OW_KEY = '6d63f2fee85575d205d162410cb26b95';
    const self = this;
    const url = `//api.openweathermap.org/data/2.5/weather?lat=${CITY_LAT}&lon=${CITY_LON}&units=imperial&appid=${OW_KEY}`;
    fetch(url)
      .then((data) => data.json())
      .then((data) => {
        const fahrenheit = Math.round(data.main.temp_max);
        !this.isCancelled && self.setState({ fahrenheit });
        const iconCode = data.weather[0].id;
        !this.isCancelled && self.setState({ iconCode });
        const celcius = Math.round((5.0 / 9.0) * (fahrenheit - 32.0));
        !this.isCancelled && self.setState({ celcius });
        !this.isCancelled &&
          setTimeout(() => {
            self.setState({ isLoading: false });
          }, 150);
      });
  };

  componentDidMount() {
    this.weatherInit();
  }

  componentWillUnmount() {
    this.isCancelled = true;
  }

  handleToggleWeather = () => {
    this.setState((state) => ({ showCelcius: !state.showCelcius }));
  };

  render() {
    const {
      isLoading,
      fahrenheit,
      celcius,
      iconCode,
      showCelcius
    } = this.state;

    return (
      <>
        {isLoading && (
          <Wrapper>
            |
            <Loading>
              <LoadingDots numTimes={8}>
                {(index) => <span key={index}>.</span>}
              </LoadingDots>
            </Loading>
          </Wrapper>
        )}
        {!isLoading && (
          <Wrapper onClick={this.handleToggleWeather}>
            |
            <span>
              <i className={`wi wi-owm-${iconCode}`} />
              {showCelcius === true ? `${celcius} °C` : `${fahrenheit} °F`}
            </span>
          </Wrapper>
        )}
      </>
    );
  }
}

export default Weather;
