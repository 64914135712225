import React from 'react';
import styled from 'styled-components';

// Context
import ContextConsumer from '../../../common/contextProvider';

// Icons
import { CloseIcon } from '../../../../assets/images/icons/icons';

const Wrapper = styled.button`
  display: block;
  border: 0;
  padding: 0;
  position: absolute;
  top: 15px;
  right: 27px;
  height: 35px;
  width: 35px;
  overflow: hidden;
  background: transparent;
  svg {
    height: 24px;
    width: 24px;
    path {
      transition: all 300ms ease-in-out;
      fill: ${props => props.theme.colors.text.light};
    }
  }
  &:hover {
    background: transparent;
    svg {
      path {
        fill: ${props => props.theme.colors.primary};
      }
    }
  }
`;

const MenuClose = () => (
  <ContextConsumer>
    {({ toggleMenu }) => <Wrapper onClick={toggleMenu}>{CloseIcon}</Wrapper>}
  </ContextConsumer>
);

export default MenuClose;
