import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

// Config
import config from '../../../../../config/website';

// Context
import ContextConsumer from '../../../common/contextProvider';

// Icons
import { ArrowRight } from '../../../../assets/images/icons/icons';

const Wrapper = styled(Link)`
  position: absolute;
  top: 0;
  left: 30px;
  height: 60px;
  line-height: 60px;
  font-size: 14px;
  text-decoration: none;
  text-transform: uppercase;
  color: ${props => props.theme.colors.text.light};
  transition: all 300ms ease-in-out;
  cursor: pointer;
  z-index: 99;
  @media only screen and (max-width: ${props => props.theme.responsive.small}) {
    display: none;
  }
  svg {
    width: 20px;
    height: 10px;
    position: absolute;
    transform: translateY(-50%);
    right: -20px;
    top: 50%;
    path {
      fill: ${props => props.theme.colors.text.light};
      transition: all 300ms ease-in-out;
    }
  }
  &:hover {
    color: ${props => props.theme.colors.primary};
    svg {
      path {
        fill: ${props => props.theme.colors.primary};
      }
    }
  }
`;

const HeaderSpecials = () => (
  <ContextConsumer>
    {({ location }) => (
      <Wrapper
        id="specials"
        className={location.pathname === '/specials/' ? 'hidden' : ''}
        to="/specials/"
        title={`Specials for ${config.siteTitle}`}
      >
        View our specials
        {ArrowRight}
      </Wrapper>
    )}
  </ContextConsumer>
);

export default HeaderSpecials;
