import React from 'react';
import styled from 'styled-components';

// Config
import config from '../../../../../config/website';

// Images
import LogoFile from '../../../../assets/images/logo/comfort-inn-2018-dark.svg';

const LogoLink = styled.div`
  position: relative;
  display: block;
  text-align: center;
  z-index: 10;
  margin-bottom: 50px;
  &::after {
    clear: both;
    content: '';
    display: table;
  }
`;

const LogoImage = styled.img`
  display: block;
  height: 100px;
  width: auto;
  margin: 0 auto;
`;

const Name = styled.span`
  margin-top: 10px;
  display: block;
  font: 700 16px/1 ${props => props.theme.fonts.body};
  color: ${props => props.theme.colors.text.dark};
  text-transform: uppercase;
  transition: opacity 0.1s, top 0s linear 0.2s, transform 0.2s, width 0.2s;
`;

const Logo = () => (
  <LogoLink>
    <LogoImage src={LogoFile} alt={config.siteTitle} />
    <Name>Sombrero</Name>
  </LogoLink>
);

export default Logo;
