import React from 'react';

// Components
import {
  Wrapper,
  BookingLogo,
  BookingClose,
  BookingForm,
  Inner,
  BookingCall
} from './files';

// Context
import ContextConsumer from '../../common/contextProvider';

const Booking = () => (
  <ContextConsumer>
    {({ bookingPanel }) => {
      return (
        <Wrapper id="booking" className={bookingPanel ? 'active' : ''}>
          <Inner>
            <BookingClose />
            <BookingLogo />
            <h2>Best Price Guaranteed</h2>
            <BookingForm />
            <BookingCall />
          </Inner>
        </Wrapper>
      );
    }}
  </ContextConsumer>
);

export default Booking;
