import React, { Component } from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql, Link } from 'gatsby';

// Components
import { RoomLinks, ApartmentLinks, SpecialLinks } from '.';

// Context
import ContextConsumer from '../../../common/contextProvider';

// Icons
import ArrowDownLight from '../../../../assets/images/icons/arrowDownLight.svg';

const List = styled.ul`
  border-bottom: 1px solid ${props => props.theme.colors.background.normal};
  margin-bottom: 20px;
  padding-bottom: 20px;
  display: block;
  li {
    display: block;
    position: relative;
    padding-right: 20px;
    a,
    span.top {
      display: block;
      font-size: 22px;
      line-height: 30px;
      font-weight: bold;
      text-transform: uppercase;
      text-decoration: none;
      color: ${props => props.theme.colors.text.light};
      padding: 5px 0;
      cursor: pointer;
      @media only screen and (max-width: ${props =>
          props.theme.responsive.small}) {
        font-size: 18px;
      }
      &:hover {
        color: ${props => props.theme.colors.primary};
      }
      &.current {
        color: ${props => props.theme.colors.primary};
      }
    }
  }
`;

const Top = styled.span`
  display: block;
  font-size: 22px;
  line-height: 30px;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  color: ${props => props.theme.colors.text.light};
  padding: 5px 0;
  cursor: pointer;
  @media only screen and (max-width: ${props => props.theme.responsive.small}) {
    font-size: 18px;
  }
  &:hover {
    color: ${props => props.theme.colors.primary};
  }
  &.current {
    color: ${props => props.theme.colors.primary};
  }
`;

const DropDown = styled.span`
  position: absolute;
  right: 0;
  top: 0;
  display: block;
  width: 14px;
  height: 8px;
  height: 40px;
  width: 100%;
  left: 0;
  background-position: center right;
  &:after {
    content: '';
    position: absolute;
    right: 6px;
    top: 0;
    bottom: 0;
    width: 14px;
    background: url(${ArrowDownLight}) no-repeat;
    background-size: 100%;
    background-size: 14px;
    background-position: center right;
    transition: all 300ms ease-in-out;
  }
  &.active {
    &:after {
      transform: rotate(180deg);
    }
  }
`;

class Links extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roomOpen: false,
      apartmentOpen: false,
      specialOpen: false
    };
  }

  componentDidMount() {
    this.toggleInitialState();
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (location !== prevProps.location) {
      this.toggleInitialState();
    }
  }

  toggleInitialState = () => {
    if (location.pathname.includes('rooms')) {
      this.setState({
        roomOpen: true,
        apartmentOpen: false,
        specialOpen: false
      });
    }
    if (location.pathname.includes('apartments')) {
      this.setState({
        apartmentOpen: true,
        roomOpen: false,
        specialOpen: false
      });
    }
    if (location.pathname.includes('specials')) {
      this.setState({
        specialOpen: true,
        roomOpen: false,
        apartmentOpen: false
      });
    }
  };

  toggleRooms = () => {
    this.setState(state => ({
      roomOpen: !state.roomOpen,
      apartmentOpen: false,
      specialOpen: false
    }));
  };

  toggleApartments = () => {
    this.setState(state => ({
      apartmentOpen: !state.apartmentOpen,
      roomOpen: false,
      specialOpen: false
    }));
  };

  toggleSpecials = () => {
    this.setState(state => ({
      specialOpen: !state.specialOpen,
      roomOpen: false,
      apartmentOpen: false
    }));
  };

  render() {
    const { roomOpen, apartmentOpen, specialOpen } = this.state;
    const { location } = this.props;
    return (
      <StaticQuery
        query={graphql`
          query {
            contentfulMenus(
              id: { eq: "582dd3a9-4d6c-5ba1-8cdc-79e444c799b6" }
            ) {
              id
              pages {
                id
                pageTitle
                menuTitle
                slug
              }
            }
          }
        `}
        render={data => {
          const { pages } = data.contentfulMenus;
          return (
            <ContextConsumer>
              {({ toggleMenu }) => (
                <List>
                  {pages.map(i => {
                    if (i.slug === 'rooms') {
                      return (
                        <li key={i.id}>
                          <Top
                            className={
                              location.pathname.includes(i.slug)
                                ? 'current'
                                : ''
                            }
                            onClick={this.toggleRooms}
                          >
                            {i.menuTitle}
                            <DropDown className={roomOpen ? 'active' : ''} />
                            <RoomLinks roomOpen={roomOpen} />
                          </Top>
                        </li>
                      );
                    }
                    if (i.slug === 'apartments') {
                      return (
                        <li key={i.id}>
                          <Top
                            className={
                              location.pathname.includes(i.slug)
                                ? 'current'
                                : ''
                            }
                            onClick={this.toggleApartments}
                          >
                            {i.menuTitle}
                            <DropDown
                              className={apartmentOpen ? 'active' : ''}
                            />
                            <ApartmentLinks apartmentOpen={apartmentOpen} />
                          </Top>
                        </li>
                      );
                    }
                    if (i.slug === 'specials') {
                      return (
                        <li key={i.id}>
                          <Top
                            className={
                              location.pathname.includes(i.slug)
                                ? 'current'
                                : ''
                            }
                            onClick={this.toggleSpecials}
                          >
                            {i.menuTitle}
                            <DropDown className={specialOpen ? 'active' : ''} />
                            <SpecialLinks specialOpen={specialOpen} />
                          </Top>
                        </li>
                      );
                    }
                    return (
                      <li key={i.id}>
                        <Link
                          activeClassName="current"
                          to={`/${i.slug}/`}
                          onClick={toggleMenu}
                          title={i.pageTitle}
                        >
                          {i.menuTitle}
                        </Link>
                      </li>
                    );
                  })}
                </List>
              )}
            </ContextConsumer>
          );
        }}
      />
    );
  }
}

export default Links;
