import React from 'react';
import styled from 'styled-components';

// Components
import { BookingInputs } from '.';

// Context
import ContextConsumer from '../../../common/contextProvider';

const Wrapper = styled.form`
  text-align: center;
`;

const SubmitButton = styled.button`
  width: 100%;
  border: none;
  position: relative;
  margin-top: 1em;
  color: ${props => props.theme.colors.text.light};
  background-color: ${props => props.theme.colors.primary};
  text-transform: uppercase;
  font-weight: 700;
  font-size: 15px;
  letter-spacing: 0.05em;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
`;

const BookingForm = () => (
  <ContextConsumer>
    {({ handleBookingSubmit }) => {
      return (
        <Wrapper onSubmit={handleBookingSubmit}>
          <BookingInputs />
          <SubmitButton type="submit" value="Submit">
            Book Direct
          </SubmitButton>
        </Wrapper>
      );
    }}
  </ContextConsumer>
);

export default BookingForm;
