import React from 'react';

// Components
import { Wrapper, Links, SecondaryLinks, Booking, Close } from './files';

// Context
import ContextConsumer from '../../common/contextProvider';

const Menu = () => (
  <ContextConsumer>
    {({ menuPanel, location }) => {
      return (
        <Wrapper id="menu" className={menuPanel ? 'active' : ''}>
          <Links location={location} />
          <SecondaryLinks />
          <Booking />
          <Close />
        </Wrapper>
      );
    }}
  </ContextConsumer>
);

export default Menu;
