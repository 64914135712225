import React from 'react';
import styled from 'styled-components';

// Context
import ContextConsumer from '../../../common/contextProvider';

const MenuButton = styled.button`
  position: absolute;
  top: 0;
  right: 180px;
  display: block;
  width: 60px;
  height: 60px;
  margin: 0 15px;
  transition: background 0.2s, transform 0.2s, opacity 0.1s, margin 0.2s;
  z-index: 20;
  transform: translateY(-100%);
  opacity: 0;
  color: ${props => props.theme.colors.text.light};
  background: transparent;
  border: 0;
  @media only screen and (max-width: ${props =>
      props.theme.responsive.medium}) {
    transform: translateY(0);
    opacity: 1;
  }
  @media only screen and (max-width: ${props => props.theme.responsive.small}) {
    right: 0;
    margin-top: 25px;
    margin-right: 0;
  }
  &:hover {
    background: transparent;
    span {
      span {
        &:first-child {
          transform: translate(0, -2px);
        }
        &:last-child {
          transform: translate(0, 2px);
        }
      }
    }
  }
`;

const Trigger = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  width: 30px;
  height: 22px;
  text-indent: -9999px;
  span {
    display: block;
    width: 100%;
    height: 2px;
    position: absolute;
    top: calc(50% - 1px);
    left: 0;
    background: ${props => props.theme.colors.background.light};
    transition: background 200ms, transform 150ms, border-radius 150ms;
    transform-origin: 50% 50%;
    &:first-child {
      top: 0;
    }
    &:last-child {
      top: auto;
      bottom: 0;
    }
  }
`;

const MenuTrigger = () => (
  <ContextConsumer>
    {({ toggleMenu }) => (
      <MenuButton id="menu-button" onClick={toggleMenu}>
        <Trigger>
          <span />
          <span />
          <span />
          Menu
        </Trigger>
      </MenuButton>
    )}
  </ContextConsumer>
);

export default MenuTrigger;
