import styled from 'styled-components';

const Wrapper = styled.header`
  position: fixed;
  top: 45px;
  left: 0;
  height: 60px;
  z-index: 100;
  background: ${(props) => props.theme.colors.background.dark};
  width: 100%;
  transition: background-color 0.2s, height 0.2s, top 0.2s;
  @media only screen and (max-width: ${(props) =>
      props.theme.responsive.small}) {
    position: fixed;
    background: transparent;
  }
  &.scrolling {
    top: 0;
    @media only screen and (max-width: ${(props) =>
        props.theme.responsive.small}) {
      background: ${(props) => props.theme.colors.background.dark};
    }
    #specials,
    #widgets {
      top: -100%;
      opacity: 0;
    }
    #logo {
      .logo-large {
        transform: translate(0, -50%);
        height: 80%;
        top: 50%;
        @media only screen and (min-width: ${(props) =>
            props.theme.responsive.small}) {
          left: 32px;
        }
        @media only screen and (max-width: ${(props) =>
            props.theme.responsive.small}) {
          left: 17px;
        }
        img {
          margin: 0;
        }
        span {
          opacity: 0;
          top: -30px;
          transform: translateY(-40px);
          width: 0;
        }
      }
      .logo-small {
        transform: translateY(-50%);
        opacity: 1;
      }
    }
    #booking-button {
      @media only screen and (min-width: ${(props) =>
          props.theme.responsive.small}) {
        margin-right: 0;
      }
    }
    #menu-button {
      transform: translateY(0);
      opacity: 1;
      @media only screen and (max-width: ${(props) =>
          props.theme.responsive.small}) {
        margin-top: 0;
        right: 0;
      }
    }
    #menu {
      opacity: 0;
      top: -30px;
      transform: translateY(-40px);
      transition: opacity 0.1s, top 0s linear 0.2s, transform 0.2s;
    }
    #mobile-phone {
      margin-top: -22px;
      @media only screen and (max-width: ${(props) =>
          props.theme.responsive.small}) {
        margin-top: -25px;
      }
    }
    #mobile-booking {
      bottom: 0;
      height: 40px;
      line-height: 40px;
      font-size: 16px;
    }
  }
  &.not-home {
    @media only screen and (max-width: ${(props) =>
        props.theme.responsive.small}) {
      background: ${(props) => props.theme.colors.background.dark};
    }
    #specials,
    #widgets {
      top: -100%;
      opacity: 0;
    }
    #logo {
      .logo-large {
        transform: translate(0, -50%);
        height: 80%;
        top: 50%;
        @media only screen and (min-width: ${(props) =>
            props.theme.responsive.small}) {
          left: 30px;
        }
        @media only screen and (max-width: ${(props) =>
            props.theme.responsive.small}) {
          left: 17px;
        }
        img {
          margin: 0;
        }
        span {
          opacity: 0;
          top: -30px;
          transform: translateY(-40px);
          width: 0;
        }
      }
      .logo-small {
        transform: translateY(-50%);
        opacity: 1;
      }
    }
    #booking-button {
      @media only screen and (min-width: ${(props) =>
          props.theme.responsive.small}) {
        margin-right: 0;
      }
    }
    #menu-button {
      transform: translateY(0);
      opacity: 1;
      @media only screen and (max-width: ${(props) =>
          props.theme.responsive.small}) {
        margin-top: 0;
        right: 0;
      }
    }
    #menu {
      opacity: 0;
      top: -30px;
      transform: translateY(-40px);
      transition: opacity 0.1s, top 0s linear 0.2s, transform 0.2s;
    }
    #mobile-phone {
      margin-top: -22px;
      @media only screen and (max-width: ${(props) =>
          props.theme.responsive.small}) {
        margin-top: -25px;
      }
    }
  }
`;

export default Wrapper;
