import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';

// Icons
import { PhoneIcon } from '../../../../assets/images/icons/icons';

const Wrapper = styled.span`
  display: none;
  position: absolute;
  top: 39px;
  right: 60px;
  width: 30px;
  height: 30px;
  margin: 0 15px;
  transition: background 0.2s, transform 0.2s, opacity 0.1s, margin 0.2s;
  z-index: 20;
  @media only screen and (max-width: ${(props) =>
      props.theme.responsive.small}) {
    display: block;
  }
  @media only screen and (max-width: ${(props) =>
      props.theme.responsive.xsmall}) {
    right: 60px;
  }
  a {
    svg {
      height: 30px;
      width: 30px;
      path {
        fill: ${(props) => props.theme.colors.text.light};
      }
    }
  }
`;

const MobilePhone = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          contentfulCompanyInformation(
            id: { eq: "59e3746d-e072-58a3-a341-04607ebd3e33" }
          ) {
            name
            phone
          }
        }
      `}
      render={(data) => {
        const { name, phone } = data.contentfulCompanyInformation;
        const cleaned = ('' + phone).replace(/\D/g, '');

        const LinkPhoneNumber = '+61' + cleaned.replace(/\b0+/g, '');

        return (
          <Wrapper id="mobile-phone">
            <a
              href={`tel:${LinkPhoneNumber}`}
              title={`Call ${name} on ${LinkPhoneNumber}`}
            >
              {PhoneIcon}
            </a>
          </Wrapper>
        );
      }}
    />
  );
};

export default MobilePhone;
