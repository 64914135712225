import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql, Link } from 'gatsby';

// Config
import config from '../../../../../config/website';

// Context
import ContextConsumer from '../../../common/contextProvider';

const List = styled.ul`
  transition: all 300ms ease-in-out;
  height: 0;
  opacity: 0;
  visibility: hidden;
  &.active {
    border-bottom: 1px solid ${props => props.theme.colors.background.normal};
    margin-bottom: 20px;
    padding-bottom: 20px;
    padding-top: 20px;
    opacity: 1;
    height: 210px;
    visibility: visible;
  }
  li {
    display: block;
    position: relative;
    padding-right: 20px;
    a {
      display: block;
      font-size: 15px !important;
      line-height: 23px !important;
      text-decoration: none;
      padding: 5px 0;
      cursor: pointer;
      @media only screen and (max-width: ${props =>
          props.theme.responsive.small}) {
        font-size: 14px !important;
      }
      &:hover,
      &.current {
        color: ${props => props.theme.colors.primary};
      }
    }
  }
`;

const RoomLinks = ({ roomOpen }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allContentfulRooms {
            edges {
              node {
                id
                seoContent {
                  menuTitle
                  pageTitle
                  slug
                }
              }
            }
          }
        }
      `}
      render={data => {
        const Rooms = data.allContentfulRooms.edges;
        return (
          <ContextConsumer>
            {({ toggleMenu }) => (
              <List className={roomOpen ? 'active' : ''}>
                <li>
                  <Link
                    activeClassName="current"
                    to="/rooms/"
                    onClick={toggleMenu}
                    title={`View all ${config.siteTitle} rooms`}
                  >
                    Overview
                  </Link>
                </li>
                {Rooms.map(({ node: room }) => (
                  <li key={room.id}>
                    <Link
                      activeClassName="current"
                      to={`/rooms/${room.seoContent.slug}/`}
                      onClick={toggleMenu}
                      title={room.seoContent.pageTitle}
                    >
                      {room.seoContent.menuTitle}
                    </Link>
                  </li>
                ))}
              </List>
            )}
          </ContextConsumer>
        );
      }}
    />
  );
};

export default RoomLinks;
