import styled from 'styled-components';

const Wrapper = styled.section`
  right: 0;
  position: fixed;
  top: 0;
  height: 100vh;
  width: 360px;
  padding: 105px 30px 30px;
  background: ${props => props.theme.colors.background.dark};
  transition: transform 0.2s;
  overflow: auto;
  z-index: 999;
  transform: translateX(100%);
  -webkit-overflow-scrolling: touch;
  @media only screen and (max-width: ${props =>
      props.theme.responsive.xsmall}) {
    padding-top: 105px 35px 35px;
    width: 100%;
  }
  &.active {
    transform: translateX(0);
  }
  &::-webkit-scrollbar {
    display: none;
  }
`;

export default Wrapper;
