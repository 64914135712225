import React from 'react';
import styled from 'styled-components';

// Context
import ContextConsumer from '../../../common/contextProvider';

const BookingButton = styled.button`
  display: block;
  width: 100%;
  height: 50px;
  margin-top: 30px;
  line-height: 50px;
  border: none;
  font-size: 15px;
  transition: margin 200ms, background 300ms;
`;

const Booking = () => (
  <ContextConsumer>
    {({ toggleBooking }) => (
      <BookingButton id="booking-button" onClick={toggleBooking}>
        Book Your Stay
      </BookingButton>
    )}
  </ContextConsumer>
);

export default Booking;
