import React from 'react';
import styled from 'styled-components';

// Context
import ContextConsumer from '../../../common/contextProvider';

// Icons
import { CloseIcon } from '../../../../assets/images/icons/icons';

const Wrapper = styled.button`
  display: flex;
  justify-content: flex-end;
  border: 0;
  padding: 0;
  position: relative;
  height: 35px;
  width: 100%;
  overflow: hidden;
  background: transparent;
  svg {
    height: 24px;
    width: 24px;
    path {
      transition: all 300ms ease-in-out;
      fill: ${props => props.theme.colors.text.dark};
    }
  }
  &:hover {
    background: transparent;
    svg {
      path {
        fill: ${props => props.theme.colors.primary};
      }
    }
  }
`;

const BookingClose = () => (
  <ContextConsumer>
    {({ toggleBooking }) => (
      <Wrapper onClick={toggleBooking}>{CloseIcon}</Wrapper>
    )}
  </ContextConsumer>
);

export default BookingClose;
