import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

// Config
import config from '../../../../../config/website';

// Context
import ContextConsumer from '../../../common/contextProvider';

const BookingButton = styled.button`
  float: right;
  border: 0;
  height: 60px;
  line-height: 60px;
  width: 180px;
  font-size: 15px;
  transition: margin 200ms, background 300ms;
  @media only screen and (min-width: ${(props) =>
      props.theme.responsive.medium}) {
    margin-right: -180px;
  }
  &:hover {
    background: ${(props) => props.theme.colors.secondary};
  }
  @media only screen and (max-width: ${(props) =>
      props.theme.responsive.small}) {
    display: none;
  }
`;

const ContactButton = styled(Link)`
  float: right;
  border: 0;
  height: 60px !important;
  line-height: 60px !important;
  width: 180px;
  font-size: 15px;
  transition: margin 200ms, background 300ms;
  @media only screen and (min-width: ${(props) =>
      props.theme.responsive.medium}) {
    margin-right: -180px;
  }
  &:hover {
    background: ${(props) => props.theme.colors.secondary};
  }
  @media only screen and (max-width: ${(props) =>
      props.theme.responsive.small}) {
    display: none !important;
  }
`;

const BookingTrigger = () => (
  <ContextConsumer>
    {({ toggleBooking, location }) => {
      if (location.pathname.includes('booking')) {
        return (
          <ContactButton
            id="booking-button"
            className="button"
            to="/enquiry/"
            title={`Send booking enquiry to ${config.siteTitle}`}
          >
            Contact
          </ContactButton>
        );
      }
      return (
        <BookingButton id="booking-button" onClick={toggleBooking}>
          Book Your Stay
        </BookingButton>
      );
    }}
  </ContextConsumer>
);

export default BookingTrigger;
