import React from 'react';
import styled from 'styled-components';
import InfiniteCalendar, { Calendar, withRange } from 'react-infinite-calendar';

// Context
import ContextConsumer from '../../../common/contextProvider';
import { DatesClose } from '.';

// Styles
import 'react-infinite-calendar/styles.css';

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 400px;
  -webkit-overflow-scrolling: touch;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
  transform: translateX(-100%);
  z-index: 1001;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  background: ${props => props.theme.colors.background.dark};
  @media (max-width: ${props => props.theme.responsive.small}) {
    width: 100%;
  }
  &.dates-open {
    visibility: visible;
    transform: translateX(0%);
  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  h3 {
    color: ${props => props.theme.colors.text.light};
    margin: 0;
    text-align: center;
    padding: 1em 0;
  }
`;

const DateRangePicker = styled(InfiniteCalendar)`
  && {
    z-index: 10;
    height: 500px;
    max-width: 100%;
    display: block;
    max-height: 100%;
    overflow: hidden;
    @media (max-width: ${props => props.theme.responsive.small}) {
      width: 100% !important;
    }
    li {
      padding-left: inherit;
      line-height: inherit;
      padding-bottom: 15px;
      margin-bottom: inherit;
      &.Cal__Day__root {
        padding: 0;
      }
    }
    .Cal__Container__wrapper {
      .Cal__Weekdays__root {
        background-color: ${props =>
          props.theme.colors.background.light} !important;
        color: ${props => props.theme.colors.text.dark} !important;
      }
      .Cal__Today__chevron {
        display: none !important;
      }
      .Cal__Today__root {
        background-color: ${props =>
          props.theme.colors.background.dark} !important;
      }
      .Cal__Day__selection {
        background-color: ${props =>
          props.theme.colors.background.light} !important;
        border-color: ${props => props.theme.colors.primary} !important;
        color: ${props => props.theme.colors.text.dark} !important;
        border: 2px solid;
      }
      .Cal__Day__range.Cal__Day__selected.Cal__Day__betweenRange
        .Cal__Day__selection {
        background-color: ${props => props.theme.colors.primary} !important;
      }
      .Cal__Day__range.Cal__Day__selected.Cal__Day__end .Cal__Day__selection {
        border-color: ${props => props.theme.colors.primary} !important;
      }
      .Cal__Day__range.Cal__Day__selected.Cal__Day__end .Cal__Day__selection {
        color: ${props => props.theme.colors.text.dark} !important;
      }
      .Cal__Day__root.Cal__Day__selected {
        color: ${props => props.theme.colors.primary} !important;
      }
      .Cal__Day__root.Cal__Day__today {
        color: ${props => props.theme.colors.background.dark} !important;
      }
    }
  }
`;

const today = new Date();

const DatesInput = () => (
  <ContextConsumer>
    {({ datesPanel, calendarDates, handleDatesChange }) => {
      return (
        <Wrapper className={datesPanel ? 'dates-open' : ''}>
          <Title>
            <h3>Select your dates</h3>
          </Title>
          <DateRangePicker
            Component={withRange(Calendar)}
            selected={{
              start: calendarDates.startDate,
              end: calendarDates.endDate
            }}
            displayOptions={{
              showHeader: false
            }}
            minDate={today}
            onSelect={handleDatesChange}
          />
          <DatesClose />
        </Wrapper>
      );
    }}
  </ContextConsumer>
);

export default DatesInput;
