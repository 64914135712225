import React from 'react';

// Components
import {
  Wrapper,
  Specials,
  Logo,
  BookingTrigger,
  Widgets,
  Menu,
  MenuTrigger,
  MobileBar,
  MobilePhone
} from './files';

// Context
import ContextConsumer from '../../common/contextProvider';

const Header = () => (
  <ContextConsumer>
    {({ isScrolling, location }) => {
      const notHome = location.pathname !== '/';
      return (
        <Wrapper
          id="header"
          className={`${notHome ? 'not-home' : ''} ${
            isScrolling ? 'scrolling' : ''
          }`}
        >
          <Specials />
          <Logo />
          <BookingTrigger />
          {!notHome && <Widgets />}
          <Menu />
          <MenuTrigger />
          {location.pathname !== '/booking/' && <MobileBar />}
          <MobilePhone />
        </Wrapper>
      );
    }}
  </ContextConsumer>
);

export default Header;
