import React from 'react';
import styled from 'styled-components';
import { Link, ModalRoutingContext } from 'gatsby-plugin-modal-routing';

// Context
import ContextConsumer from '../contextProvider';

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  background: ${(props) => props.theme.colors.misc.overlay};
  cursor: pointer;
  transition: all 300ms ease-in-out;
  &.active {
    opacity: 1;
    visibility: visible;
    z-index: 997;
  }
  &.modal-page {
    opacity: 1;
    visibility: visible;
    z-index: -1;
  }
`;

const ModalLink = styled(Link)`
  height: 100vh;
  width: 100%;
  position: fixed;
`;

const Freezer = () => (
  <ContextConsumer>
    {({ menuPanel, datesPanel, toggleFreezer }) => {
      return (
        <ModalRoutingContext.Consumer>
          {({ modal, closeTo }) => (
            <Wrapper
              onClick={!modal ? toggleFreezer : null}
              className={`${menuPanel || datesPanel ? 'active' : ''} ${
                modal ? 'modal-page' : ''
              }`}
            >
              {modal && <ModalLink to={closeTo} />}
            </Wrapper>
          )}
        </ModalRoutingContext.Consumer>
      );
    }}
  </ContextConsumer>
);

export default Freezer;
