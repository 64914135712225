import React from 'react';

// Components
import { Wrapper, Inner, Menu, Social, Contact, Copyright } from './files';

const Footer = () => (
  <Wrapper id="footer">
    <Inner>
      <Menu />
      <Social />
      <Contact />
      <Copyright />
    </Inner>
  </Wrapper>
);

export default Footer;
