import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql, Link } from 'gatsby';

const Wrapper = styled.span`
  position: absolute;
  top: 110px;
  right: 20px;
  margin-left: 220px;
  transition: opacity 0.1s, transform 0.2s, top 200ms;
  top: 127px;
  padding-top: 20px;
  @media (max-width: ${props => props.theme.responsive.large}) {
    margin-left: 180px;
  }
  @media only screen and (max-width: ${props =>
      props.theme.responsive.medium}) {
    display: none;
  }
`;

const List = styled.ul`
  text-align: right;
  li {
    display: inline-block;
    position: relative;
    margin-right: 30px;
    &:last-child,
    &:last-child(2) {
      position: static;
    }
    a {
      display: block;
      line-height: 30px;
      font-size: 19px;
      font-weight: 700;
      letter-spacing: 0.05em;
      color: ${props => props.theme.colors.text.light};
      text-transform: uppercase;
      text-decoration: none;
      @media (min-width: ${props => props.theme.responsive.medium}) {
        font-size: 14px;
      }
      @media (min-width: ${props => props.theme.responsive.large}) {
        font-size: 16px;
      }
      @media (min-width: ${props => props.theme.responsive.xlarge}) {
        font-size: 19px;
      }
      &:hover {
        color: ${props => props.theme.colors.primary};
      }
    }
  }
`;

const HeaderMenu = () => (
  <StaticQuery
    query={graphql`
      query {
        contentfulMenus(id: { eq: "582dd3a9-4d6c-5ba1-8cdc-79e444c799b6" }) {
          id
          pages {
            id
            pageTitle
            menuTitle
            slug
          }
        }
      }
    `}
    render={data => {
      const { pages } = data.contentfulMenus;
      return (
        <Wrapper id="menu">
          <List>
            {pages.map(i => (
              <li key={i.id}>
                <Link to={`/${i.slug}/`} title={i.pageTitle}>
                  {i.menuTitle}
                </Link>
              </li>
            ))}
          </List>
        </Wrapper>
      );
    }}
  />
);

export default HeaderMenu;
