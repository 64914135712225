import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql, Link } from 'gatsby';

// Config
import config from '../../../../../config/website';

// Context
import ContextConsumer from '../../../common/contextProvider';

const Wrapper = styled.p`
  margin: 0;
  font-weight: 300;
  font-size: 1.125em;
  padding-top: 1rem;
  font-family: ${props => props.theme.fonts.header};
  text-align: center;
  span {
    @media (max-width: ${props => props.theme.responsive.small}) {
      display: block;
    }
  }
`;

const BookingCall = () => (
  <ContextConsumer>
    {({ countryCode, toggleBooking, bookingPanel }) => {
      return (
        <StaticQuery
          query={graphql`
            query {
              contentfulCompanyInformation(
                id: { eq: "59e3746d-e072-58a3-a341-04607ebd3e33" }
              ) {
                phone
              }
            }
          `}
          render={data => {
            const { phone } = data.contentfulCompanyInformation;
            const cleaned = ('' + phone).replace(/\D/g, '');

            const LinkPhoneNumber = '+61' + cleaned.replace(/\b0+/g, '');

            function LocalPhoneNumber() {
              const match = cleaned.match(/^(\d{2})(\d{4})(\d{4})$/);
              if (match) {
                return '(' + match[1] + ') ' + match[2] + ' ' + match[3];
              }
              return null;
            }

            function IntPhoneNumber() {
              const match = cleaned.match(/^(\d{1})(\d{1})(\d{4})(\d{4})$/);
              if (match) {
                return '+61 (' + match[2] + ') ' + match[3] + ' ' + match[4];
              }
              return null;
            }

            return (
              <Wrapper>
                Alternatively, call us on&nbsp;
                <a
                  href={`tel:${LinkPhoneNumber}`}
                  title={`Call ${config.siteTitleShort} on ${LinkPhoneNumber}`}
                >
                  {countryCode !== 'AU' ? (
                    <IntPhoneNumber />
                  ) : (
                    <LocalPhoneNumber />
                  )}
                </a>{' '}
                <span>
                  or send an{' '}
                  <Link
                    to="/enquiry/"
                    onClick={bookingPanel ? toggleBooking : null}
                    title={`Send a bookings enquiry to ${
                      config.siteTitleShort
                    }`}
                  >
                    enquiry
                  </Link>
                  .
                </span>
              </Wrapper>
            );
          }}
        />
      );
    }}
  </ContextConsumer>
);

export default BookingCall;
