import styled from 'styled-components';

const Inner = styled.div`
  position: relative;
  max-width: 1240px;
  margin: 0 auto;
  padding: 0 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @media only screen and (max-width: ${props => props.theme.responsive.small}) {
    padding: 0 20px;
  }
`;

export default Inner;
