import React from 'react';

// Components
import { Wrapper } from './files';

const Banner = () => (
  <Wrapper to="/covid-response">
    <span>COVID-19 update</span>
    <div>Read more</div>
  </Wrapper>
);

export default Banner;
