import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';

// Icons
import {
  Facebook,
  Twitter,
  TripAdvisor
} from '../../../../assets/images/icons/icons';

const List = styled.ul`
  font-size: 0;
  width: 100%;
  display: inline-block;
  margin: 30px 0;
  li {
    display: inline-block;
    margin-left: 20px;
    &:first-child {
      margin-left: 0;
    }
    &:after {
      clear: both;
      content: '';
      display: table;
    }
    a {
      position: relative;
      display: block;
      width: 41px;
      height: 41px;
      overflow: hidden;
      text-decoration: none;
      @media only screen and (max-width: ${props =>
          props.theme.responsive.small}) {
        display: inline-block;
      }
      svg {
        border: 1px solid ${props => props.theme.colors.text.normal};
        border-radius: 50%;
        transition: all 300ms ease-in-out;
        path {
          transition: all 300ms ease-in-out;
          fill: ${props => props.theme.colors.text.normal};
        }
      }
      &:hover {
        svg {
          border: 1px solid ${props => props.theme.colors.primary};
          path {
            fill: ${props => props.theme.colors.primary};
          }
        }
      }
    }
  }
`;

const Social = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          contentfulCompanyInformation {
            facebook
            twitter
            tripAdvisor
          }
        }
      `}
      render={data => {
        const {
          facebook,
          twitter,
          tripAdvisor
        } = data.contentfulCompanyInformation;
        return (
          <List>
            <li>
              <a href={facebook} target="_blank" rel="noopener noreferrer">
                {Facebook}
              </a>
            </li>
            <li>
              <a href={twitter} target="_blank" rel="noopener noreferrer">
                {Twitter}
              </a>
            </li>
            <li>
              <a href={tripAdvisor} target="_blank" rel="noopener noreferrer">
                {TripAdvisor}
              </a>
            </li>
          </List>
        );
      }}
    />
  );
};

export default Social;
