import React from 'react';
import { navigate } from 'gatsby';
import moment from 'moment';

const debounce = (func, wait) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
};

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

const { Provider, Consumer } = React.createContext();

class ContextProvider extends React.Component {
  constructor() {
    super();
    this.state = {
      countryCode: 'AU',
      scrollPositionY: 10,
      menuPanel: false,
      bookingPanel: false,
      datesPanel: false,
      calendarDates: {
        startDate: moment().add(1, 'days'),
        endDate: moment().add(2, 'days'),
        nights: 1
      },
      adults: 1,
      childs: 0,
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      message: '',
      consent: false,
      successfulSend: false,
      errorSend: false,
      contentfulTypeID: '',
      promoCode: '',
      roomRates: null,
      currency: 'AUD',
      galleryid: '',
      imageindex: null,
      showModal: false,
      showVideo: false
    };
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', this.handleScroll);
    }
    this.handleScroll();
    this.getCountryCode();
    this.getRoomGridRates();
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('scroll', this.handleScroll);
    }
    this.isCancelled = true;
  }

  getCountryCode = () => {
    const self = this;
    const url = `//ipapi.co/json/`;
    fetch(url)
      .then((data) => data.json())
      .then((data) => {
        const countryCode = data.country;
        !this.isCancelled && self.setState({ countryCode });
      });
  };

  getRoomGridRates = () => {
    const { calendarDates, adults, childs, currency } = this.state;

    const self = this;

    const grid = 'https://sjp.resonline.com.au/api/get-hotels-rooms-grids';
    const hotelID = '275331,3485916';
    const affiliateID = '27281';
    const nights = calendarDates.nights;

    !this.isCancelled &&
      self.setState({
        roomRates: null
      });

    const proxyurl = 'https://cors-anywhere.herokuapp.com/';
    const url =
      grid +
      '?q=' +
      hotelID +
      '&checkin=' +
      moment(calendarDates.startDate).format('YYYY-MM-DD') +
      '&nights=' +
      nights +
      '&adults=' +
      adults +
      '&children=' +
      childs +
      '&currency=' +
      currency +
      '&webid=' +
      affiliateID;
    fetch(proxyurl + url)
      .then((data) => data.json())
      .then((data) => {
        !this.isCancelled &&
          self.setState({
            roomRates: data
          });
      });
  };

  handleScroll = debounce(() => {
    const scrollPositionY = +window.scrollY;
    return this.setState({ scrollPositionY });
  }, 15);

  toggleMenu = () => {
    this.setState((state) => ({
      menuPanel: !state.menuPanel,
      bookingPanel: false
    }));
  };

  newBookingLink = () => {
    if (typeof window !== 'undefined') {
      window
        .open(
          'https://bookings.centiumsoftware.com/SA/Prospect/Comfort-Inn-and-Suites-Sombrero',
          '_blank'
        )
        .focus();
    }
  };

  toggleBooking = () => {
    // Replaced to link to https://bookings.centiumsoftware.com/SA/Prospect/Comfort-Inn-and-Suites-Sombrero
    // this.setState((state) => ({
    //   bookingPanel: !state.bookingPanel,
    //   menuPanel: false
    // }));
    this.newBookingLink();
    this.setState({ menuPanel: false });
  };

  toggleFreezer = () => {
    this.setState({
      datesPanel: false,
      menuPanel: false
    });
  };

  toggleDates = () => {
    this.setState((state) => ({ datesPanel: !state.datesPanel }));
  };

  // eslint-disable-next-line no-unused-vars
  toggleRoomBooking = (event) => {
    this.newBookingLink();
    // Replaced to link to https://bookings.centiumsoftware.com/SA/Prospect/Comfort-Inn-and-Suites-Sombrero

    // this.setState({
    //   bookingPanel: true,
    //   contentfulTypeID: event.target.value
    // });
  };

  // eslint-disable-next-line no-unused-vars
  toggleSpecialBooking = (event) => {
    this.newBookingLink();
    // Replaced to link to https://bookings.centiumsoftware.com/SA/Prospect/Comfort-Inn-and-Suites-Sombrero

    // this.setState({
    //   bookingPanel: true,
    //   promoCode: event.target.value
    // });
  };

  // eslint-disable-next-line no-unused-vars
  toggleEventBooking = (event) => {
    this.newBookingLink();
    // Replaced to link to https://bookings.centiumsoftware.com/SA/Prospect/Comfort-Inn-and-Suites-Sombrero

    // const StartDate = moment(event.target.value);
    // const EndDate = moment(StartDate).add(2, 'days');

    // this.setState({
    //   calendarDates: {
    //     startDate: StartDate,
    //     endDate: EndDate,
    //     nights: Math.ceil((EndDate - StartDate) / (1000 * 60 * 60 * 24))
    //   }
    // });
    // navigate('booking');
  };

  handleDatesChange = (data) => {
    if (data.eventType === 3) {
      this.setState({
        calendarDates: {
          startDate: data.start,
          endDate: data.end,
          nights: Math.ceil((data.end - data.start) / (1000 * 60 * 60 * 24))
        },
        datesPanel: false
      });
    }
  };

  handleCurrencyChange = (event) => {
    this.setState({ currency: event.target.value });
  };

  handleChangeAdults = (event) => {
    this.setState({ adults: event.target.value });
  };

  handleAddAdults = () => {
    this.setState((prevState) => {
      return { adults: prevState.adults + 1 };
    });
  };

  handleMinusAdults = () => {
    this.setState((prevState) => {
      return { adults: prevState.adults - 1 };
    });
  };

  handleChangeChilds = (event) => {
    this.setState({ childs: event.target.value });
  };

  handleAddChilds = () => {
    this.setState((prevState) => {
      return { childs: prevState.childs + 1 };
    });
  };

  handleMinusChilds = () => {
    this.setState((prevState) => {
      return { childs: prevState.childs - 1 };
    });
  };

  handleBookingSubmit = (event) => {
    navigate('/booking/');
    this.setState((state) => ({
      bookingPanel: !state.bookingPanel
    }));
    event.preventDefault();
  };

  handleSelectBooking = (event) => {
    const { calendarDates, adults, childs, promoCode } = this.state;
    const SecureTravelPayments =
      `https://securetravelpayments.com/booking?checkin=` +
      moment(calendarDates.startDate).format('DD-MMM-YYYY') +
      `&nights=` +
      calendarDates.nights +
      `&adults=` +
      adults +
      `&children=` +
      childs +
      `&hotelId=275331&roomId=` +
      event.target.value +
      `&affiliateid=27281&voucherCode=` +
      promoCode +
      `&isGadget=1&returnUrl=https://www.sombrero.com.au/&errorUrl=null`;
    window.open(SecureTravelPayments, '_blank');
  };

  handleEnquiryChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleEnquiryConsent = () => {
    this.setState((state) => ({ consent: !state.consent }));
  };

  handleEnquiryReset = () => {
    this.setState({
      calendarDates: {
        startDate: moment().add(1, 'days'),
        endDate: moment().add(2, 'days')
      },
      adults: 1,
      childs: 0,
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      message: '',
      successfulSend: false,
      errorSend: false
    });
  };

  handleEnquirySubmit = (e) => {
    const {
      calendarDates: { startDate, endDate },
      adults,
      childs,
      firstName,
      lastName,
      email,
      phone,
      message
    } = this.state;
    e.preventDefault();
    const form = e.target;
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state
      })
    })
      .then(() => {
        this.setState({ successfulSend: true, errorSend: false });
        window.analytics.track('Enquiry', {
          calendarDates: { startDate, endDate },
          adults,
          childs,
          firstName,
          lastName,
          email,
          phone,
          message
        });
      })
      .catch((error) => alert(error));
  };

  handleEnquiryError = () => {
    this.setState({ successfulSend: false, errorSend: true });
  };

  toggleModalOpen = (event) => {
    const galleryid = event.currentTarget.dataset.galleryid;
    const imageindex = event.currentTarget.dataset.imageid;
    this.setState({
      showModal: true,
      imageindex,
      galleryid
    });
  };

  toggleBookingModalOpen = (event) => {
    const galleryid = event.currentTarget.dataset.galleryid;
    this.setState({
      showModal: true,
      galleryid
    });
  };

  toggleBookingModalClose = () => {
    this.setState({
      showModal: false
    });
  };

  toggleRoomModalOpen = () => {
    this.setState({
      showModal: true
    });
  };

  toggleModalClose = () => {
    this.setState({
      showModal: false
    });
  };

  toggleVideo = () => {
    this.setState((state) => ({ showVideo: !state.showVideo }));
  };

  render() {
    const { children, location } = this.props;
    const {
      scrollPositionY,
      countryCode,
      menuPanel,
      bookingPanel,
      datesPanel,
      calendarDates,
      adults,
      childs,
      currency,
      roomRates,
      contentfulTypeID,
      promoCode,
      showModal,
      imageindex,
      galleryid,
      firstName,
      lastName,
      email,
      phone,
      message,
      consent,
      successfulSend,
      errorSend,
      showVideo
    } = this.state;

    const isScrolling = !!scrollPositionY;

    const enquiryVerified =
      firstName !== '' && email !== '' && consent !== false;

    const dataPass = {
      // Props
      location,
      // State
      countryCode,
      menuPanel,
      bookingPanel,
      datesPanel,
      calendarDates,
      adults,
      childs,
      firstName,
      lastName,
      email,
      phone,
      message,
      consent,
      successfulSend,
      errorSend,
      currency,
      roomRates,
      contentfulTypeID,
      promoCode,
      showModal,
      showVideo,
      imageindex,
      galleryid,
      // Variables
      isScrolling,
      enquiryVerified,
      // Functions
      getRoomGridRates: this.getRoomGridRates,
      toggleMenu: this.toggleMenu,
      toggleBooking: this.toggleBooking,
      toggleDates: this.toggleDates,
      handleDatesChange: this.handleDatesChange,
      handleChangeAdults: this.handleChangeAdults,
      handleAddAdults: this.handleAddAdults,
      handleMinusAdults: this.handleMinusAdults,
      handleChangeChilds: this.handleChangeChilds,
      handleAddChilds: this.handleAddChilds,
      handleMinusChilds: this.handleMinusChilds,
      handleBookingSubmit: this.handleBookingSubmit,
      handleSelectBooking: this.handleSelectBooking,
      handleCurrencyChange: this.handleCurrencyChange,
      handleEnquiryChange: this.handleEnquiryChange,
      handleEnquiryConsent: this.handleEnquiryConsent,
      handleEnquiryReset: this.handleEnquiryReset,
      handleEnquirySubmit: this.handleEnquirySubmit,
      handleEnquiryError: this.handleEnquiryError,
      toggleRoomBooking: this.toggleRoomBooking,
      toggleFreezer: this.toggleFreezer,
      toggleRoomModalOpen: this.toggleRoomModalOpen,
      toggleSpecialBooking: this.toggleSpecialBooking,
      toggleEventBooking: this.toggleEventBooking,
      toggleBookingModalOpen: this.toggleBookingModalOpen,
      toggleBookingModalClose: this.toggleBookingModalClose,
      toggleModalOpen: this.toggleModalOpen,
      toggleModalClose: this.toggleModalClose,
      toggleVideo: this.toggleVideo
    };

    return <Provider value={dataPass}>{children}</Provider>;
  }
}

export { Consumer as default, ContextProvider };
