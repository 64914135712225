const theme = {
  colors: {
    text: {
      light: '#ffffff',
      normal: '#666666',
      dark: '#111111'
    },
    background: {
      light: '#ffffff',
      lightAlt: '#f8f8f8',
      normal: '#2B2B2B',
      dark: '#151515'
    },
    misc: {
      border: '#cccccc',
      input: '#f1f1f1',
      selection: 'rgba(245, 185, 61, 0.91)',
      overlay: 'rgba(245, 185, 61, 0.91)'
    },
    primary: '#ffb901',
    secondary: '#ff9800'
  },
  fonts: {
    body: 'Lato, sans-serif;',
    header: 'Lato, sans-serif;'
  },
  responsive: {
    zero: '0px',
    xxsmall: '350px',
    xsmall: '550px',
    small: '767px',
    medium: '960px',
    large: '1200px',
    xlarge: '1440px'
  }
};

export default theme;
