import React from 'react';
import styled from 'styled-components';

// Context
import ContextConsumer from '../../../common/contextProvider';

const BookingButton = styled.button`
  display: none;
  position: fixed;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  padding: 0 30px;
  z-index: 20;
  height: 60px;
  font-size: 20px;
  line-height: 60px;
  border: 0;
  transition: height 0.2s, line-height 0.2s, background 0.4s, font-size 0.2s,
    bottom 0.2s;
  &:hover {
    background: ${(props) => props.theme.colors.secondary};
  }
  @media only screen and (max-width: ${(props) =>
      props.theme.responsive.small}) {
    display: block;
    width: 100%;
    bottom: 0;
    height: 65px;
    line-height: 65px;
    font-size: 16px;
  }
`;

const MobileBar = () => (
  <ContextConsumer>
    {({ toggleBooking }) => (
      <BookingButton id="mobile-booking" onClick={toggleBooking}>
        Book Your Stay
      </BookingButton>
    )}
  </ContextConsumer>
);

export default MobileBar;
