import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { ConsentManager } from '@segment/consent-manager';
// import inEU from '@segment/in-eu';

const ConsentBar = styled.div`
  .exqsvii0 {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99;
    background: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.text.dark};
    .exqsvii1 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media screen and (max-width: ${props => props.theme.responsive.small}) {
        flex-direction: column;
      }
      p {
        text-align: left;
        padding: 1em;
        font-size: 13px;
        letter-spacing: 0px;
        line-height: 1.7;
        margin-bottom: 0;
        @media screen and (max-width: ${props =>
            props.theme.responsive.small}) {
          text-align: center;
        }
        &.exqsvii2 {
          button {
            cursor: pointer;
            white-space: nowrap;
            width: 100%;
            text-transform: uppercase;
            background-color: ${props => props.theme.colors.background.dark};
            color: ${props => props.theme.colors.text.light};
            padding: 1em 2em;
            display: block;
            text-decoration: none;
            transition: all 300ms ease-in-out;
          }
        }
      }
    }
  }
`;

export default function() {
  const bannerContent = (
    <span>
      We use cookies to collect data to improve your experience on our site. By
      using our website, you’re agreeing to the collection of data as described
      in our{' '}
      <Link
        to="/privacy/"
        title="Website Privacy Policy"
        aria-label="Website Privacy Policy"
      >
        Privacy Policy
      </Link>
      .
    </span>
  );
  const bannerSubContent = 'Change Preferences';
  const preferencesDialogTitle = 'Website Data Collection Preferences';
  const preferencesDialogContent =
    'We use data collected by cookies and JavaScript libraries to improve your browsing experience, analyze site traffic, deliver personalized advertisements, and increase the overall performance of our site.';
  const cancelDialogTitle = 'Are you sure you want to cancel?';
  const cancelDialogContent =
    'Your preferences have not been saved. By continuing to use our website, you՚re agreeing to our Website Data Collection Policy.';

  return (
    <ConsentBar>
      <ConsentManager
        writeKey={process.env.GATSBY_SEGMENT_WRITE_KEY}
        // shouldRequireConsent={inEU}
        bannerContent={bannerContent}
        bannerSubContent={bannerSubContent}
        preferencesDialogTitle={preferencesDialogTitle}
        preferencesDialogContent={preferencesDialogContent}
        cancelDialogTitle={cancelDialogTitle}
        cancelDialogContent={cancelDialogContent}
      />
    </ConsentBar>
  );
}
