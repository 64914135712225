import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql, Link } from 'gatsby';

const Wrapper = styled.nav`
  display: inline-block;
  width: 80%;
  margin: 30px auto;
  @media only screen and (max-width: ${props => props.theme.responsive.small}) {
    order: -3;
    margin: 0;
    width: 100%;
  }
`;

const List = styled.ul`
  margin-bottom: 10px;
  &:after {
    clear: both;
    content: '';
    display: table;
  }
  li {
    margin: 10px 20px;
    display: inline-block;
    @media only screen and (max-width: ${props =>
        props.theme.responsive.small}) {
      display: block;
      text-align: left;
    }
    a {
      font: 16px/19px ${props => props.theme.fonts.body};
      text-align: center;
      display: block;
      text-transform: uppercase;
      text-decoration: none;
      color: ${props => props.theme.colors.text.light};
      @media only screen and (max-width: ${props =>
          props.theme.responsive.small}) {
        line-height: 1;
        padding: 10px 0;
      }
      &:hover {
        color: ${props => props.theme.colors.primary};
      }
    }
  }
`;

const Menu = () => (
  <StaticQuery
    query={graphql`
      query {
        mainMenu: contentfulMenus(
          id: { eq: "582dd3a9-4d6c-5ba1-8cdc-79e444c799b6" }
        ) {
          pages {
            id
            pageTitle
            menuTitle
            slug
          }
        }
        footerMenu: contentfulMenus(
          id: { eq: "9822bef5-4c31-57e8-be5d-1d7eeff2674c" }
        ) {
          pages {
            id
            pageTitle
            menuTitle
            slug
          }
        }
        legalMenu: contentfulMenus(
          id: { eq: "3b17d3c8-a3b5-529f-9994-3876a8642e59" }
        ) {
          pages {
            id
            pageTitle
            menuTitle
            slug
          }
        }
      }
    `}
    render={data => {
      const mainMenu = data.mainMenu.pages;
      const footerMenu = data.footerMenu.pages;
      const legalMenu = data.legalMenu.pages;

      return (
        <Wrapper>
          <List>
            {mainMenu.map(i => (
              <li key={i.id}>
                <Link to={`/${i.slug}/`} title={i.pageTitle}>
                  {i.menuTitle}
                </Link>
              </li>
            ))}
            {footerMenu.map(i => (
              <li key={i.id}>
                <Link to={`/${i.slug}/`} title={i.pageTitle}>
                  {i.menuTitle}
                </Link>
              </li>
            ))}
            {legalMenu.map(i => (
              <li key={i.id}>
                <Link to={`/${i.slug}/`} title={i.pageTitle}>
                  {i.menuTitle}
                </Link>
              </li>
            ))}
          </List>
        </Wrapper>
      );
    }}
  />
);

export default Menu;
