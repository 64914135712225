import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const Line = styled.small`
  width: 100%;
  color: ${(props) => props.theme.colors.text.normal};
  font: 14px/17px ${(props) => props.theme.fonts.body};
  margin-bottom: 10px;
  a {
    color: ${(props) => props.theme.colors.text.normal};
    &:hover {
      color: ${(props) => props.theme.colors.primary};
    }
  }
`;

const Copyright = () => {
  const Year = new Date();
  return (
    <>
      <Line>
        Copyright © {Year.getFullYear()},&nbsp;
        <Link to="/">Comfort Inn &amp; Suites Sombrero.</Link>
      </Line>
      <Line>
        Website by: &nbsp;
        <a
          href="https://discovrbookings.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Discovr Bookings
        </a>
      </Line>
    </>
  );
};

export default Copyright;
