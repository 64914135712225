import React from 'react';
import { ThemeProvider } from 'styled-components';

// Components
import Header from '../components/global/header';
import PageWrapper from '../components/common/pageWrapper';
import Footer from '../components/global/footer';
import Menu from '../components/global/menu';
import Booking from '../components/global/booking';
import { DatesInput } from '../components/global/booking/files';
import Freezer from '../components/common/freezer';
import ConsentManager from '../components/common/consentManager';

// Context
import { ContextProvider } from '../components/common/contextProvider';

// Fonts
import 'typeface-lato';

// Styles
import Theme from '../assets/styles/theme';
import GlobalStyle from '../assets/styles/global';
import Banner from '../components/global/banner';

const Layout = ({ children, location }) => (
  <ThemeProvider theme={Theme}>
    <ContextProvider location={location}>
      <ConsentManager />
      <Banner />
      <Header />
      <PageWrapper>{children}</PageWrapper>
      <Footer />
      <Menu />
      <Booking />
      <DatesInput />
      <Freezer />
      <GlobalStyle />
    </ContextProvider>
  </ThemeProvider>
);

export default Layout;
