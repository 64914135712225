import React from 'react';
import styled from 'styled-components';

import { Contact, Weather } from '.';

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  right: 30px;
  display: flex;
  height: 60px;
  line-height: 60px;
  font-size: 14px;
  text-decoration: none;
  text-transform: uppercase;
  color: ${props => props.theme.colors.text.light};
  transition: all 300ms ease-in-out;
  cursor: pointer;
  z-index: 99;
  @media only screen and (max-width: ${props =>
      props.theme.responsive.medium}) {
    display: none;
  }
`;

const Widgets = () => (
  <Wrapper id="widgets">
    <Contact />
    <Weather />
  </Wrapper>
);

export default Widgets;
