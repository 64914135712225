import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

// Config
import config from '../../../../../config/website';

// Images
import LogoFile from '../../../../assets/images/logo/comfort-inn-2018.svg';

const LogoLink = styled.div`
  position: absolute;
  top: 90px;
  left: 60px;
  height: 100px;
  transition: top 0.2s, left 0.2s, opacity 0.2s, transform 0.3s;
  text-decoration: none;
  text-align: center;
  z-index: 10;
  opacity: 1;
  @media only screen and (min-width: ${props => props.theme.responsive.small}) {
    display: block;
  }
  @media only screen and (max-width: ${props => props.theme.responsive.small}) {
    height: 70px;
    top: 10px;
    left: 15px;
    transform: translate(0, 0);
  }
`;

const LogoImage = styled.img`
  display: block;
  height: 100%;
  width: auto;
  margin: 0 auto;
`;

const Name = styled.span`
  display: inline-block;
  padding-top: 10px;
  font: 700 13px/1 ${props => props.theme.fonts.body};
  color: ${props => props.theme.colors.text.light};
  text-transform: uppercase;
  transition: opacity 0.1s, top 0s linear 0.2s, transform 0.2s, width 0.2s;
  @media only screen and (max-width: ${props => props.theme.responsive.small}) {
    left: 105px;
    font: 700 11px/1 ${props => props.theme.fonts.body};
  }
`;

const NameScroll = styled.div`
  color: ${props => props.theme.colors.text.light};
  font: 700 22px/1 ${props => props.theme.fonts.body};
  position: absolute;
  top: 50%;
  left: 100px;
  transition: transform 0.3s, opacity 0.2s;
  transform: translateY(-100%);
  opacity: 0;
  text-transform: uppercase;
  @media only screen and (max-width: ${props =>
      props.theme.responsive.xsmall}) {
    left: 90px;
    font: 700 20px/1 ${props => props.theme.fonts.body};
  }
`;

const Logo = () => (
  <Link id="logo" to="/" title={config.siteTitle}>
    <LogoLink className="logo-large">
      <LogoImage src={LogoFile} alt={config.siteTitle} />
      <Name>Sombrero</Name>
    </LogoLink>
    <NameScroll className="logo-small">Sombrero</NameScroll>
  </Link>
);

export default Logo;
