// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-apartments-jsx": () => import("./../../src/pages/apartments.jsx" /* webpackChunkName: "component---src-pages-apartments-jsx" */),
  "component---src-pages-booking-jsx": () => import("./../../src/pages/booking.jsx" /* webpackChunkName: "component---src-pages-booking-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-covid-response-jsx": () => import("./../../src/pages/covid-response.jsx" /* webpackChunkName: "component---src-pages-covid-response-jsx" */),
  "component---src-pages-enquiry-jsx": () => import("./../../src/pages/enquiry.jsx" /* webpackChunkName: "component---src-pages-enquiry-jsx" */),
  "component---src-pages-events-jsx": () => import("./../../src/pages/events.jsx" /* webpackChunkName: "component---src-pages-events-jsx" */),
  "component---src-pages-gallery-jsx": () => import("./../../src/pages/gallery.jsx" /* webpackChunkName: "component---src-pages-gallery-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-location-jsx": () => import("./../../src/pages/location.jsx" /* webpackChunkName: "component---src-pages-location-jsx" */),
  "component---src-pages-privacy-jsx": () => import("./../../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */),
  "component---src-pages-property-jsx": () => import("./../../src/pages/property.jsx" /* webpackChunkName: "component---src-pages-property-jsx" */),
  "component---src-pages-reviews-jsx": () => import("./../../src/pages/reviews.jsx" /* webpackChunkName: "component---src-pages-reviews-jsx" */),
  "component---src-pages-rooms-jsx": () => import("./../../src/pages/rooms.jsx" /* webpackChunkName: "component---src-pages-rooms-jsx" */),
  "component---src-pages-south-australia-jsx": () => import("./../../src/pages/south-australia.jsx" /* webpackChunkName: "component---src-pages-south-australia-jsx" */),
  "component---src-pages-specials-jsx": () => import("./../../src/pages/specials.jsx" /* webpackChunkName: "component---src-pages-specials-jsx" */),
  "component---src-pages-terms-jsx": () => import("./../../src/pages/terms.jsx" /* webpackChunkName: "component---src-pages-terms-jsx" */),
  "component---src-templates-apartment-jsx": () => import("./../../src/templates/apartment.jsx" /* webpackChunkName: "component---src-templates-apartment-jsx" */),
  "component---src-templates-event-jsx": () => import("./../../src/templates/event.jsx" /* webpackChunkName: "component---src-templates-event-jsx" */),
  "component---src-templates-room-jsx": () => import("./../../src/templates/room.jsx" /* webpackChunkName: "component---src-templates-room-jsx" */),
  "component---src-templates-special-jsx": () => import("./../../src/templates/special.jsx" /* webpackChunkName: "component---src-templates-special-jsx" */)
}

