import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql, Link } from 'gatsby';

// Context
import ContextConsumer from '../../../common/contextProvider';

const List = styled.ul`
  border-bottom: 1px solid ${props => props.theme.colors.background.normal};
  margin-bottom: 20px;
  padding-bottom: 20px;
  display: block;
  li {
    display: block;
    position: relative;
    padding-right: 20px;
    a {
      display: block;
      font-size: 17px;
      line-height: 23px;
      font-weight: bold;
      text-transform: uppercase;
      text-decoration: none;
      color: ${props => props.theme.colors.text.light};
      padding: 5px 0;
      cursor: pointer;
      @media only screen and (max-width: ${props =>
          props.theme.responsive.small}) {
        font-size: 14px;
      }
      &:hover {
        color: ${props => props.theme.colors.primary};
      }
    }
  }
`;

const SecondaryLinks = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          contentfulMenus(id: { eq: "9822bef5-4c31-57e8-be5d-1d7eeff2674c" }) {
            id
            pages {
              id
              pageTitle
              menuTitle
              slug
            }
          }
        }
      `}
      render={data => {
        const { pages } = data.contentfulMenus;
        return (
          <ContextConsumer>
            {({ toggleMenu }) => (
              <List>
                {pages.map(i => (
                  <li key={i.id}>
                    <Link
                      to={`/${i.slug}/`}
                      onClick={toggleMenu}
                      title={i.pageTitle}
                    >
                      {i.menuTitle}
                    </Link>
                  </li>
                ))}
              </List>
            )}
          </ContextConsumer>
        );
      }}
    />
  );
};

export default SecondaryLinks;
