import React from 'react';
import styled from 'styled-components';

// Context
import ContextConsumer from '../contextProvider';

const Wrapper = styled.main`
  transition: all 300ms;
  transform: ${(props) =>
    props.isScrolling ? 'translateY(-60px)' : 'translateY(45px)'};
  margin-bottom: ${(props) => (props.isScrolling ? '-60px' : '')};
`;

const PageWrapper = ({ children }) => (
  <ContextConsumer>
    {({ isScrolling }) => {
      return <Wrapper isScrolling={isScrolling}>{children}</Wrapper>;
    }}
  </ContextConsumer>
);

export default PageWrapper;
