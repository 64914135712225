import styled from 'styled-components';

const Wrapper = styled.section`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 996;
  background-color: ${props => props.theme.colors.background.light};
  -webkit-overflow-scrolling: touch;
  opacity: 1;
  visibility: hidden;
  transition: all 500ms ease-in-out;
  transform: translateY(-100%);
  overflow: hidden;
  padding: 30px 0 0;
  &.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0%);
  }
  &::after {
    clear: both;
    content: '';
    display: table;
  }
  &::-webkit-scrollbar {
    display: none;
  }
`;

export default Wrapper;
